body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

* {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

@font-face {
	font-family: regular;
	src: url(/static/media/AvenirNextLTPro-Regular.b5ed9330.otf);
}

@font-face {
	font-family: semibold;
	src: url(/static/media/AvenirNextLTPro-Demi.553decd4.otf);
}

@font-face {
	font-family: medium;
	src: url(/static/media/AvenirLTStd-Medium.11f78470.otf);
}

@font-face {
	font-family: bold;
	src: url(/static/media/AvenirNextLTPro-Bold.91d8ff8a.otf);
}

body {
	background-color: #F2F2F2;
}

a {
	text-decoration: none;
	color: inherit;
}

.app-loading-container {
	position: absolute;
	width: 150px;
	margin-left: auto;
	margin-right: auto;
	left: 0;
	right: 0;
	padding-top: 260px;
}

.manage-feeds-loading-container {
	display: block;
	width: 150px;
	padding-top: 180px;
	margin-left: 320px;
}

.updates-loading-container {
	display: block;
	width: 150px;
	padding-top: 280px;
	margin-left: 700px;
}

.mobile-menu-button {
	display: none;
	color: #999999;
	padding: 10px 12px 9px 12px;
	border-radius: 20px;
	background-color: #DDDDDD;
}

.sidebar-container {
	width: 210px;
	position: fixed;
	left: 0px;
	top: 0px;
	bottom: 0px;
	background-color: white;
	padding: 25px 30px 30px 30px;
}

.sidebar-title {
	font-family: semibold;
	font-size: 28px;
}

.sidebar-icon {
	width: 26px;
	margin-top: 3px;
	margin-right: 2px;
	float: left;
}

.current-user {
	font-family: semibold;
	background-color: #DDDDDD;
	display: inline-block;
	margin-top: 7px;
	color: #999999;
	font-size: 14px;
	padding: 5px 10px 4px 10px;
	border-radius: 8px;
	cursor: default;
}

.current-user .fas {
	margin-left: 5px;
	font-size: 13px;
	cursor: pointer;
}

.current-user .fas:hover {
	opacity: .5;
}

.sidebar-button-label {
	margin-left: 15px;
	font-family: semibold;
	font-size: 15px;
}

.sidebar-button-label .fas {
	font-size: 13px;
	margin-right: 10px;
	margin-top: 2px;
}

.sidebar-button-label .fa-bars {
	color: #999999;
}

.sidebar-bottom-container {
	position: absolute;
	bottom: 0px;
	right: 0px;
	left: 0px;
	overflow: auto;
	padding: 20px 30px 22px 30px;
	background-color: white;
}

.sidebar-separator-bottom {
	width: 210px;
	height: 2px;
	background-color: #DDDDDD;
}

.sidebar-bottom-button {
	width: 100%;
	padding-top: 10px;
	padding-bottom: 9px;
	background-color: #DFDFDF;
	border-radius: 8px;
	margin-top: 18px;
	color: black;
	position: relative;
	cursor: pointer;
	-webkit-transition: ease all .2s;
	transition: ease all .2s;
}

.sidebar-buttons-container {
	padding-top: 15px;
}

.update-queue-button {
	width: 100%;
	padding-top: 10px;
	padding-bottom: 9px;
	background-color: #DFDFDF;
	border-radius: 8px;
	margin-top: 15px;
	color: black;
	position: relative;
	cursor: pointer;
	-webkit-transition: ease all .2s;
	transition: ease all .2s;
}

.active-sidebar-button .update-queue-button,
.active-sidebar-button .sidebar-bottom-button {
	background-color: #AAAAAA !important;
	color: white;
}

.coverage-paused-button {
	width: 165px;
	padding-top: 9px;
	padding-bottom: 8px;
	background-color: #333333;
	border-radius: 8px;
	margin-top: 15px;
	color: white;
	position: relative;
	-webkit-user-select: none;
	   -moz-user-select: none;
	    -ms-user-select: none;
	        user-select: none;
}

.coverage-paused-button .sidebar-button-label {
	font-size: 15px;
}

.coverage-paused-button .fas {
	font-size: 13px;
}

.active-sidebar-button .fas {
	color: white !important;
}

.sidebar-bottom-button .fas {
	color: #999999;
}

.submit-update-button {
	display: inline-block;
	padding: 8px 15px 7px 0px;
	background-color: #47CE4D;
	border-radius: 8px;
	margin-top: 16px;
	color: white;
	position: relative;
	cursor: pointer;
}

.submit-update-button .sidebar-button-label {
	font-size: 15px !important;
}

.submit-update-button .sidebar-button-label .fas {
	font-size: 13px !important;
}

.update-queue-button:hover, .submit-update-button:hover {
	opacity: .8;
}

.update-count {
	position: absolute;
	color: white;
	width: auto;
	padding-left: 6px;
	padding-right: 6px;
	padding-top: 4px;
	padding-bottom: 2px;
	background-color: red;
	border-radius: 13px;
	right: 14px;
	top: 9px;
	font-size: 12px;
	font-family: bold;
}

.sidebar-separator {
	width: 100%;
	height: 2px;
	background-color: #DDDDDD;
	margin-top: 18px;
}

.sidebar-drafts-container {
	margin-top: 10px;
	overflow: scroll;
	height: calc(100% - 508px);
	width: 240px;
}

.sidebar-drafts-container-paused {
	margin-top: 10px;
	overflow: scroll;
	height: calc(100% - 600px);
	width: 240px;
}

.update-draft-container {
	width: 210px;
	padding-top: 12px;
	padding-bottom: 12px;
	background-color: #DFDFDF;
	border-radius: 12px;
	margin-top: 14px;
	color: #929292;
	position: relative;
	cursor: pointer;
	-webkit-transition: ease all .3s;
	transition: ease all .3s;
}

.active-draft-container .update-draft-container {
	width: 240px;
	border-radius: 12px 0px 0px 12px;
}

.active-draft-container .update-draft-container .fa-chevron-right {
	display:none;
}

.update-draft-container:hover {
	opacity: .8;
}

.active-draft-container .update-draft-container:hover {
	opacity: 1;
}

.feed-slug {
	display: inline;
	margin-left: 9px;
	font-family: semibold;
	color: white;
	padding: 5px 8px 5px 8px;
	border-radius: 20px;
}

.feed-slug .fas {
	font-size: 14px;
	margin-right: 3px;
}

.update-draft-top-container .fa-chevron-right {
	position: absolute;
	right: 12px;
	top: 24px;
	color: white;
}

.update-status-label {
	font-family: bold;
	opacity: .8;
	margin-left: 13px;
	margin-top: 10px;
	margin-bottom: -5px;
	font-size: 12px;
}

.active-status-label {
	color: white;
}

.sidebar-logout-button {
	position: absolute;
	bottom: 35px;
	padding: 15px 15px 14px 14px;
	border-radius: 8px;
	color: #999999;;
	font-size: 16px;
	background-color: #DFDFDF;
	cursor: pointer;
	margin-left: -7px;
}

.sidebar-guide-button {
	position: absolute;
	bottom: 30px;
	width: 198px;
	left: 24px;
	right: 23px;
	background-color: #DFDFDF;
	border-radius: 8px;
	font-size: 16px;
	padding: 13px 13px 13px 10px;
	font-family: semibold;
  overflow: auto;
	cursor: pointer;
}

.sidebar-guide-button:hover {
	opacity: .8;
}

.sidebar-guide-button .fa-book {
	font-size: 16px;
	margin-right: 10px;
	margin-left: 7px;
	color: #999999;
}

.sidebar-guide-button .fa-chevron-right {
	color: #999999;
	position: absolute;
	right: 15px;
	margin-top: 1px;
	font-size: 15px;
}

.sidebar-bottom-button .fa-chevron-right {
	 color: #999999;
 	position: absolute;
 	right: 7px;
 	margin-top: 1px;
 	font-size: 15px;
 }

 .feed-emoji {
	 margin-left: 8px;
 }

.queue-container {
	width: calc(100% - 500px);
	margin-left: 340px;
	padding-bottom: 50px;
	padding-top: 55px;
}

.queue-top-container {
	float: left;
	margin-bottom: 40px;
	display: -webkit-flex;
	display: flex;
}

.queue-right-container {
	position: fixed;
	width: 331px;
	top: 0px;
	bottom: 0px;
	right: 0px;
	overflow: scroll;
	padding: 20px 40px 40px 40px;
	background-color: white;
}

.queue-left-container {
	width: calc(100% - 320px);
	border-radius: 8px;
	padding: 0px 0px 20px 2px;
	margin-bottom: 40px;
	clear: both;
}

.queued-update {
	width: calc(100% - 26px);
	background-color: white;
	border-radius: 8px;
	margin-top: 35px;
	height: auto;
	padding: 20px 20px 9px 6px;
	overflow: hidden;
}

.queued-update-left-container {
	float: left;
}

.queued-update-right-container {
	float: right;
}

.queued-update-bottom-container {
	width: 100%;
	margin-top: 20px;
	padding: 14px 20px 0px 20px;
	overflow: hidden;
  position: relative;
}

.queued-update-tweet-container {
	width: 49%;
	float: left;
}

.queued-update-updates-container {
	width: calc(49% - 75px);
	height: 100%;
	position: absolute;
  right: 40px;
  top: 23px;
	overflow: scroll;
	font-size: 14px;
	background-color: #EEEEEE;
	border-radius: 8px;
	padding: 0px 15px 0px 15px;
}

.queued-update-updates-container .update {
	margin-top: 20px;
}

.queued-update-updates-container .update-twitter-container {
	width: 355px !important;
}

.queued-update-updates-container .update-question-text {
	font-size: 14px;
	padding: 6px 11px 5px 11px;
}

.queued-update-updates-container .feed-published-banner {
	font-size: 14px;
	line-height:1.4em;
}

.queued-update-updates-container .unpublished-updates-banner {
	font-size: 14px;
}

.queued-update-updates-container .unpublished-updates-banner .fas {
	font-size: 12px;
}

.queued-update-updates-container .feed-published-banner .far {
	font-size: 13px;
	line-height:1.4em;
}

.queued-update-updates-container .update-quote-source {
	margin-top: 4px;
}

.queued-update-updates-container .importance-label {
	margin-top: 0px;
	font-size: 13px;
}

.queued-update-updates-container .importance-label .fas {
	font-size: 10px;
}

.queued-update-updates-container .feed-update-container {
	width: 100%;
}

.queued-update-updates-container .feed-update-container .feed-slug {
	margin-top: -2px;
	padding-left: 9px;
	padding-bottom: 3px;
	padding-top: 4px;
	font-size: 15px;
	margin-left: 8px;
	display: inline-block;
}

.queued-update-updates-container .feed-update-container .feed-update-details {
	margin-top: 7px;
}

.queued-update-updates-container .feed-update-container .feed-update-button {
	margin-top: -38px;
}

.queue-updates .feed-slug {
	display: inline-block;
	margin-bottom: 10px;
	margin-top: 24px;
	margin-left: 2px;
	padding-bottom: 0px;
	padding-left: 0px;
	font-size: 15px;
	/* color: #333333 !important; */
}

.queue-updates .feed-slug .fas {
	font-size: 13px;
}

.queue-updates .update {
	margin-top: 0px !important;
}

.queue-reject-button {
	float: right;
	background-color: #f3f3f3;
	border-radius: 8px;
	margin-top: -6px;
	margin-right: 15px;
	text-align: center;
	color: #999999;
	font-family: semibold;
	font-size: 15px;
	padding: 9px 24px 6px 24px;
	cursor: pointer;
	-webkit-transition: ease all .15s;
	transition: ease all .15s;
}

.queue-claim-button {
	float: right;
	width: 80px;
	background-color: #47CE4D;
	border-radius: 8px;
	margin-top: -5px;
	text-align: center;
	color: white;
	font-family: semibold;
	padding: 7px 0px 5px 0px;
	cursor: pointer;
	-webkit-transition: ease all .15s;
	transition: ease all .15s;
}

.queue-swap-button {
	float: right;
	width: 55px;
	margin-top: -5px;
	text-align: center;
	color: #999999;
	margin-right: 15px;
	font-family: semibold;
	padding: 7px 0px 5px 0px;
	cursor: pointer;
	-webkit-transition: ease all .15s;
	transition: ease all .15s;
}

.queued-update-right-container .confirm-warning {
	color: white;
	padding: 9px 20px 6px 20px;
}

.queue-claim-button:hover, .queue-reject-button:hover, .queue-swap-button:hover {
	-webkit-transform: scale(0.98);
	        transform: scale(0.98);
}

.queue-claim-button:active, .queue-reject-button:active, .queue-swap-button:active {
	-webkit-transform: scale(0.95);
	        transform: scale(0.95);
}

.queue-section-label {
	font-family: bold;
	font-size: 12px;
	color: #BBBBBB;
	margin-bottom: 14px;
}

.queue-label {
	font-family: bold;
	font-size: 12px;
	color: #BBBBBB;
	margin-bottom: 14px;
	margin-top: 75px;
}

.second-label {
	margin-top: 30px;
}

.feed-buttons-container {
	background-color: white;
	padding-top: 15px;
	padding-bottom: 15px;
	padding-right: 15px;
	width: calc(100% - 338px);
	overflow: auto;
  white-space: nowrap;
	border-radius: 12px;
	padding-left: 5px;
}

.batch-queue-container {
	margin-top: 60px;
}

.feed-buttons-container .feed-slug {
	padding: 7px 9px 5px 9px;
}

::-webkit-scrollbar {
    width: 0px;
		height: 4px;
    background: transparent;
		border-radius: 2px;
}

::-webkit-scrollbar-thumb {
    background: #DDDDDD;
		border-radius: 2px;
}

.feed-button {
	cursor: pointer;
	display:inline-block;
	-webkit-transition: ease all .15s;
	transition: ease all .15s;
	font-size: 15px;
}

.feed-button:hover {
	-webkit-transform: scale(0.98);
	        transform: scale(0.98);
}

.selected-feed-button {
	-webkit-transform: scale(0.95) !important;
	        transform: scale(0.95) !important;
	opacity: .6;
}

.queue-count {
	background-color: white;
	margin-left: 5px;
	border-radius: 15px;
	padding: 4px 4px 2px 4px;
	font-size: 12px;
}

.queued-question-container {
	font-family: semibold;
	background-color: white;
	border-radius: 8px;
	color: #444444;
	font-size: 20px;
	height: 220px;
	line-height: 1.4em;
	margin: 9px 0px 10px -5px;
	padding: 12px 16px 0px 16px;
}

.user-id {
	font-family: courier;
	font-weight: bold;
	color: #333333;
}

.questions-section-title {
	font-size: 19px;
	font-family: semibold;
	color: #666666;
	margin-top: 40px;
	margin-bottom: -12px;
}

.draft-container {
	margin-left: 320px;
	padding-right: 60px;
}

.draft-left-container {
	width: calc(100% - 400px);
	float: left;
	padding-top: 40px;
}

.draft-right-container {
	position: fixed;
	width: 330px;
	top: 0px;
	bottom: 0px;
	overflow: scroll;
	right: 0px;
	padding: 40px;
	background-color: white;
}

.draft-notes-container {
	width: calc(100% - 30px);
	padding: 12px 15px 12px 15px;
	background-color: #F5F5F5;
	border-radius: 8px;
	margin-top: 10px;
	position: relative;
	margin-bottom: 30px;
	font-family:semibold;
}

.draft-section-label {
	font-family: bold;
	font-size: 12px;
	color: #BBBBBB;
}

.draft-type-selector-container {
	border-radius: 8px;
	font-family: semibold;
	padding: 9px 0px 7px 0px;
	line-height: 1.4em;
	color: #333333;
	margin-bottom: -30px;
	margin-left: -5px;
}

.update-info-container {
	background-color: white;
	margin-top: 10px;
	font-family: semibold;
	height: 24px;
	padding: 16px 16px 15px 13px;
	line-height: 1.4em;
	border-radius: 8px;
	color: #333333;
	margin-bottom: 30px;
}

.update-info-container .feed-slug {
	margin-left: 0px;
}

.update-scout-label {
	color: #999999;
	font-size: 14px;
	margin-left: 8px;
	font-family: semibold;
}

.update-scout-label .scout-name {
	color: #333333;
}

.update-info-left {
	float: left;
}

.draft-actions-container {
	display: inline;
	width: 130px;
	margin-left: 16px;
}

.draft-action-button {
	float: right;
	background-color: #CCCCCC;
	font-size: 13px;
	border-radius: 8px;
	margin-top: -0px;
	text-align: center;
	color: white;
	font-family: semibold;
	padding: 1px 12px 0px 12px;
	margin-right: 10px;
	cursor: pointer;
}

.draft-action-button:hover {
	opacity: .8;
}

.update-info-right {
	width: 200px;
	float: right;
	margin-top: 0px;
}

.update-stopwatch-container {
	width: auto;
	padding: 8px 12px 4px 12px;
	border-radius: 8px;
	background-color: #F5F5F5;
	float: right;
	margin-top: -5px;
	text-align: center;
}

.post-type-selector {
	display: -webkit-flex;
	display: flex;
	-webkit-justify-content: space-between;
	        justify-content: space-between;
}

.post-type {
	background-color: white;
	width: 95px;
	padding-top: 8px;
	padding-bottom: 8px;
	text-align: center;
	border-radius: 8px;
	cursor: pointer;
	color: #333333;
	margin-right: 7px;
	margin-left: 7px;
}

.post-type:hover {
	background-color: #FCFCFC;
}

.active-post-type {
	background-color: #666666 !important;
	color: white;
}

.post-type .far, .post-type .fab, .post-type .fas {
	font-size: 13px;
	display: block;
	margin: 0px auto;
	margin-top: 5px;
	margin-bottom: 4px;
}

.update-draft-label {
	margin-top: 60px;
}

.update-preview-label {
	margin-top: 22px;
}

.admin-input-container {
	margin-top: 10px;
	padding: 0px 0px 8px 0px;
	border-radius: 8px;
	margin-left: -5px;
}

.admin-input {
	width: calc(100% - 25px);
	display: block;
	font-family: semibold;
	font-size: 16px;
	color: #333333;
	line-height: 1.4em;
	border-radius: 5px;
	padding: 9px;
	margin: 0px auto;
	margin-top: 12px;
	margin-bottom: 10px;
	border: 0px;
}

.article-url-input {
	margin-bottom: 35px;
}

.admin-post-input {
	height: 110px;
}

.admin-text-input {
	height: 20px;
}

.admin-caption-input {
	height: 70px;
}

textarea:focus, input:focus {
	outline-width: 0;
}

.admin-post-submit {
	text-align: center;
	color: white;
	padding: 11px 19px 10px 16px;
	display: inline-block;
	font-family: bold;
	font-size: 12px;
	border-radius: 8px;
	background-color: #666666; /* #00a8ff */
	margin-bottom: 8px;
	margin-top: 40px;
	cursor: pointer;
	-webkit-transition: ease all .15s;
	transition: ease all .15s;
	margin-bottom: 70px;
	clear: both;
	float: left;
}

.admin-post-submit:hover {
	opacity: .9;
}

.admin-post-submit .fas {
	font-size: 14px;
	margin-right: 5px;
}

.create-top-news-draft-button {
	text-align: center;
	color: white;
	padding: 11px 19px 10px 16px;
	display: inline-block;
	font-family: bold;
	font-size: 12px;
	border-radius: 8px;
	background-color: #BBBBBB; /* #00a8ff */
	margin-top: 40px;
	cursor: pointer;
	-webkit-transition: ease all .15s;
	transition: ease all .15s;
	margin-bottom: 50px;
	margin-left: 20px;
	float: left;
}

.create-top-news-draft-button:hover {
	opacity: .8;
}

.create-top-news-draft-button .fas {
	font-size: 14px;
	margin-right: 5px;
}

.submit-confirm {
	background-color: #4cd137;
}

.update-preview-container {
	width: 339px;
	margin-top: 10px;
	margin-bottom: -15px;
	overflow: auto;
}

.update-preview {
	background-color: white;
	border-radius: 8px;
	border: 2px solid #DEDEDE;
	padding: 9px 12px 9px 12px;
	font-family: semibold;
	font-size: 14px;
	line-height: 1.3em;
}

.update-preview-footer {
	width: 105%;
	margin-left: -8px;
	margin-bottom: -10px;
}

.update-preview .update-preview-image {
	width: 100%;
	border-radius: 5px;
	margin-top: 3px;
	margin-bottom: 3px;
}

.update-preview .update-preview-video {
	width: 100%;
	height: 185px;
	margin-top: 3px;
	margin-bottom: 3px;
}

.update-preview .update-link-button {
	font-size: 13px !important;
	background-color: #EEEEEE;
	color: #999999;
	border-radius: 8px;
	padding: 7px 10px 5px 10px !important;
	margin-top: 8px;
	margin-bottom: 8px;
	cursor: pointer;
	display: inline-block;
	float: left;
	clear: left;
}

.update-preview .update-link-button .fa-chevron-right {
	font-size: 11px;
	margin-top: 2px;
	margin-left: 6px;
	float: right;
}

.update-preview .update-link-button:hover {
	opacity: .8;
}

.update-preview .update-quote-details .fa-comment {
	float: left;
	margin-right: 4px;
	color: #888888;
	font-size: 12px;
	margin-top: 2px;
}

.update-preview .update-question-text {
	display: inline-block;
	font-size: 14px;
	background-color: #EEEEEE;
	color: #444444;
	border-radius: 8px;
	padding: 4px 10px 4px 10px;
	margin-top: 3px;
	margin-bottom: 7px;
	opacity: .8;
}

.source-input-container {
	width: 70%;
	margin-top: 10px;
	font-family: semibold;
	line-height: 1.4em;
	border-radius: 8px;
	color: #333333;
}

.update-source-label {
	margin-top: 48px;
}

.update-crosspost-label {
	margin-top: 80px;
}

.update-time-label {
	margin-top: 86px;
}

.update-time-label span {
	cursor: pointer;
	text-decoration: underline;
}

.update-radios-container {
	clear: both;
	padding-top: 24px;
}

.update-radio-button {
	width: 16px;
	height: 16px;
	border-radius: 30px;
	border: 4px solid white;
	background-color: white;
	cursor: pointer;
	float: left;
	-webkit-transition: ease all .2s;
	transition: ease all .2s;
}

.update-radio-button:hover {
	opacity: .8;
}

.image-radios-input {
	margin-top: -15px;
	margin-bottom: 25px;
}

.update-radio-label {
	float: left;
	font-family: bold;
	margin-right: 18px;
	margin-left: 8px;
	color: #BBBBBB;
	margin-top: 7px;
	font-size: 12px;
}

.update-checkbox-button {
	width: 16px;
	height: 16px;
	border-radius: 2px;
	border: 4px solid white;
	background-color: white;
	cursor: pointer;
	float: left;
	-webkit-transition: ease all .2s;
	transition: ease all .2s;
}

.update-source-input {
	width: 100%;
}

.update-crosspost-input {
	width: 65% !important;
}

.update-gif-message {
	font-family: semibold;
	color: red;
	margin-left: 5px;
	padding-top: 5px;
}

.update-time-input {
	width: 97% !important;
	cursor: pointer;
}

.update-time-input:hover {
	background-color: #FAFAFA;
}

.sketch-picker {
	z-index: 999999;
	position: absolute;
	top: 215px;
	left: 321px;
}

.latest-updates-label {
	margin-top: 30px;
	margin-bottom: -10px;
}

.sidebar-updates-container {
	font-size: 14px;
}

.sidebar-updates-container .update-container {
	background-color: #f5f5f5;
	cursor: default;
}

.sidebar-updates-container .update-twitter-container {
	width: 303px !important;
}

.sidebar-updates-container .update {
	margin-top: 25px;
}

.sidebar-updates-container .update-bottom-crosspost-container {
	font-size: 13px;
}

.sidebar-updates-container .update-bottom-crosspost-container i {
	font-size: 11px;
	padding-top: 2px;
}

.sidebar-updates-container .update-link-button {
	background-color: white;
	color: #555555;
}

.sidebar-updates-container .update-quote-details {
	background-color: white;
	color: #555555;
}

.sidebar-updates-container .feed-published-banner {
	font-size: 14px;
	line-height:1.4em;
}

.sidebar-updates-container .feed-published-banner .far {
	font-size: 13px;
	line-height:1.4em;
}

.sidebar-updates-container .feed-archived-banner {
	width: calc(100% - 25px);
	padding-right: 10px;
	font-size: 14px;
	line-height:1.4em;
}

.sidebar-updates-container .feed-archived-banner .fas {
	font-size: 13px;
	line-height:1.4em;
}

.sidebar-updates-container .unpublished-updates-banner {
	font-size: 14px;
}

.sidebar-updates-container .unpublished-updates-banner .fas {
	font-size: 12px;
}

.sidebar-updates-container .update-quote-source {
	margin-top: 4px;
}

.sidebar-updates-container .update-question-text {
	font-size: 14px;
	padding: 6px 11px 5px 11px;
}

.sidebar-updates-container .importance-label {
	margin-top: 0px;
	font-size: 13px;
}

.sidebar-updates-container .importance-label .fas {
	font-size: 10px;
}

.sidebar-updates-container .feed-update-container {
	width: 100%;
}

.sidebar-updates-container .feed-update-container .feed-slug {
	margin-top: -2px;
	padding-left: 9px;
	padding-bottom: 3px;
	padding-top: 4px;
	font-size: 15px;
	margin-left: 8px;
	margin-bottom: 0px;
	display: inline-block;
}

.sidebar-updates-container .feed-update-container .feed-update-details {
	margin-top: -3px !important;
}

.draft-right-item .sidebar-updates-container .feed-update-container .feed-update-details {
	margin-top: 7px !important;
}

.sidebar-updates-container .feed-update-container .feed-update-button {
	margin-top: -38px;
}

.sidebar-updates-container .update-article-container {
	background-color: white;
	margin-top: 5px;
	margin-bottom: 3px;
	padding-top: 5px;
}

.sidebar-updates-container .update-article-container .article-update-title {
	font-size: 14px;
	margin-left: -3px;
}

.sidebar-updates-container .update-article-container .article-update-favicon {
	margin-left: -4px;
}

.sidebar-updates-container .update-article-container .article-update-site {
	font-size: 13px;
}

.source-link {
	font-family: semibold;
	text-overflow: ellipsis;
	background-color: #f3f3f3;
	color: #666666;
	white-space: nowrap;
  overflow: hidden;
	padding: 15px 15px 12px 15px;
	border-radius: 12px;
	-webkit-transition: ease all .15s;
	transition: ease all .15s;
	margin-top: 12px;
}

.source-link:hover {
	-webkit-transform: scale(0.98);
	        transform: scale(0.98);
}

.source-link:active {
	-webkit-transform: scale(0.95);
	        transform: scale(0.95);
}

.source-link i {
	padding-right: 7px;
	font-size: 15px;
}

.draft-mobile-source .source-link {
	background-color: white;
}

.top-news-notice {
	margin-top: 50px;
	color: #999999;
	font-size: 14px;
	cursor: pointer;
	font-family: bold;
}

.draft-mobile-source {
	margin-bottom: 30px;
	display: none;
}

.modal-container {
	display: none;
	width: 420px;
	position: fixed;
	top: 50%;
	left: 50%;
	margin-top: -290px;
	margin-left: -250px;
	z-index: 1001;
	background-color: #F5F5F5;
	border-radius: 12px;
	padding: 20px 40px 20px 40px;
}

.darken {
	position: fixed;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	z-index: 1000;
	background-color: black;
	opacity: .7;
	display: none;
	cursor: pointer;
}

.modal-container input {
	width: calc(100% - 18px);
	display: block;
	font-family: semibold;
	font-size: 16px;
	color: #333333;
	line-height: 1.4em;
	border-radius: 5px;
	padding: 9px;
	margin: 0px auto;
	margin-top: 20px;
	margin-bottom: 14px;
	height: 20px;
	border: 0px;
}

.modal-container .modal-notes-input {
	height: 60px;
}

.feeds-selector {
	font-family: semibold;
	padding-top: 5px;
}

.css-1aya2g8, .css-2o5izw {
	font-family: semibold;
	background-color: white !important;
	border-radius: 5px !important;
	border: 0px !important;
	height: 40px;
	padding-top: -0px !important;
}

.css-2o5izw {
	box-shadow: 0px 0px 0px 1px #999999 !important;
}

.css-rsyb7x {
	height: 32px;
}

.css-rsyb7x input {
	margin-top: 4px;
}

.css-1rtrksz {
	height: 42px;
}

.modal-container textarea {
	width: calc(100% - 18px);
	display: block;
	font-family: semibold;
	font-size: 16px;
	color: #333333;
	line-height: 1.4em;
	border-radius: 5px;
	padding: 9px;
	margin: 0px auto;
	margin-top: 20px;
	margin-bottom: 14px;
	border: 0px;
	height: 85px;
}

.modal-container select {
	border: 0px;
	font-family: semibold;
	background-color: white;
	font-size: 16px;
	-webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  padding: 11px 12px 9px 12px;
	width: 100px;
	margin-top: 4px;
	color: #757575;
}

.modal-container select:focus {
	outline: 0;
}

.submit-input-container .fas {
	margin-left: -18px;
	color: #757575;
	vertical-align: top;
	margin-top: 14px;
}

.submit-input-container select {
	width: 160px;
}

.modal-submit-button {
	text-align: center;
	color: white;
	display: inline-block;
	font-family: bold;
	font-size: 12px;
	padding: 9px 20px 8px 20px;
	border-radius: 12px;
	background-color: #00a8ff;
	cursor: pointer;
	margin-bottom: 15px;
	margin-top: 30px;
}

.modal-submit-button:hover {
	opacity: .9;
}

.modal-container .modal-submit-button {
	background-color: #4cd137;
}

.modal-container .fas {
	margin-right: 5px;
	font-size: 13px;
}

.modal-title {
	font-size: 18px;
	text-align: left;
	font-family: semibold;
}

.feeds-container {
	width: calc(100% - 500px);
	margin-left: 360px;
	padding-bottom: 50px;
	padding-top: 55px;
}

.manage-feeds-container {
	width: calc(100% - 312px);
	border-radius: 8px;
	margin-bottom: 40px;
	margin-top: 30px;
}

.feeds-right-container {
	position: fixed;
	width: 280px;
	top: 0px;
	bottom: 0px;
	right: 0px;
	overflow: scroll;
	padding: 20px 40px 40px 40px;
	background-color: white;
}

.feeds-right-container .feed-slug {
	margin-left: 0px;
	opacity: 1;
}

.feeds-right-container .user-leaderboard-item {
	padding: 16px 15px 16px 13px;
	-webkit-transition: ease all .1s;
	transition: ease all .1s;
}

.feeds-right-container .user-leaderboard-item:hover {
	-webkit-transform: scale(0.98);
	        transform: scale(0.98);
}

.feeds-right-container .user-leaderboard-item:active {
	-webkit-transform: scale(0.95);
	        transform: scale(0.95);
}

.manage-feeds-top-container {
	float: left;
	margin-bottom: 75px;
	display: -webkit-flex;
	display: flex;
}

.manage-feed-description {
	margin: 35px 15px 0px 15px;
	font-family: medium;
	color: #999999;
	font-size: 15px;
	width: 785px;
	line-height: 1.3em;
	clear: both;
}

.managed-feed-buttons-container {
	display: -webkit-flex;
	display: flex;
	-webkit-flex-wrap: wrap;
	        flex-wrap: wrap;
	margin: 2px 0px 8px 15px;
}

.manage-feed-button {
	background-color: #CCCCCC;
	font-size: 13px;
	border-radius: 8px;
	margin-top: 8px;
	text-align: center;
	color: white;
	font-family: semibold;
	padding: 4px 12px 3px 12px;
	margin-right: 10px;
}

.manage-feed-button:hover {
	opacity: .8;
}

.feed-streaming-button {
	background-color: #87D1F0;
}

.feed-live-button {
	background-color: #ff8f8a;
}

.new-update-button {
	text-align: center;
	padding: 1px 4px 0px 4px;
	background-color: #2ecc71;
	margin-top: 11px;
	height: 16px;
}

.new-update-button-big {
	background-color: #47CE4E;
}

.managed-feed {
	width: calc(100% - 25px);
	background-color: white;
	border-radius: 8px;
	height: auto;
	padding: 17px 20px 5px 4px;
	cursor: pointer;
	overflow: hidden;
	margin-bottom: 30px;
}

.manage-updates-container .managed-feed {
	margin-bottom: 55px;
}

.feed-details-label {
	color: #999999;
	font-size: 14px;
	margin-left: 15px;
	font-family: semibold;
	margin-top: 5px;
	margin-bottom: 3px;
	float: right;
}

.feed-details-label .bold-text {
	color: #333333;
}

.feed-input-container {
	margin-top: 10px;
	font-family: semibold;
	line-height: 1.4em;
	color: #333333;
	margin-bottom: 26px;
	overflow: auto;
}

.archived-feeds-container .feed-slug {
	opacity: .7;
}

.unpublished-feeds-container .feed-slug {
	opacity: .7;
}

.feed-input-container .feed-slug {
	margin-left: 14px;
	display: inline-block;
	padding: 3px 7px 2px 7px;
	margin-top: 6px;
}

.feed-input-container .color-slug {
	margin-left: 0px;
	padding: 7px 10px 6px 10px;
	border-radius: 8px;
	cursor: pointer;
	margin-top: 0px;
	margin-bottom: 7px;
}

.feed-input-container .color-slug:hover {
	-webkit-transform: scale(0.98);
	        transform: scale(0.98);
}

.feed-input-container .color-slug:active {
	-webkit-transform: scale(0.95);
	        transform: scale(0.95);
}

.feed-input {
	width: 100%;
	display: block;
	font-family: semibold;
	font-size: 16px;
	color: #333333;
	line-height: 1.4em;
	border-radius: 8px;
	padding: 10px;
	margin: 0px auto;
	margin-top: 12px;
	margin-bottom: 10px;
	border: 0px;
}

.feed-image-container {
	width: 50%;
}

.feed-text-input {
	height: 20px;
	float: left;
	margin-top: 0px;
}

.feed-image-input {
	margin-bottom: 15px;
	width: 70%;
}

.emoji-input {
	margin-bottom: 5px;
	cursor: pointer;
	font-size: 22px;
	padding-top: 16px;
	padding-left: 12px;
	width: 26px;
	-webkit-transition: ease all .15s;
	transition: ease all .15s;
}

.emoji-input:hover {
	-webkit-transform: scale(0.98);
	        transform: scale(0.98);
}

.emoji-input:active {
	-webkit-transform: scale(0.95);
	        transform: scale(0.95);
}

.feed-description-input {
	width: 96%;
	float: left;
	margin-top: 0px;
	height: 70px;
}

.feed-name-input {
	width: 130px;
}

.feed-color-input {
	width: 85px;
}

.feed-category-input {
	width: 160px;
}

.feed-keywords-input {
	width: 220px;
}

textarea:focus, input:focus {
	outline-width: 0;
}

.feed-radios-container {
	clear: both;
	padding-top: 1px;
	margin-bottom: 54px;
}

.managed-feed-left-container {
	float: left;
}

.manage-feeds-top-button {
	padding: 10px 15px 7px 15px;
	border-radius: 8px;
	text-align: center;
	font-size: 15px;
	color: white;
	font-family: semibold;
	cursor: pointer;
	margin-right: 18px;
	-webkit-transition: ease all .15s;
	transition: ease all .15s;
}

.manage-feeds-top-button:hover {
	-webkit-transform: scale(0.98);
	        transform: scale(0.98);
}

.manage-feeds-top-button:active {
	-webkit-transform: scale(0.95);
	        transform: scale(0.95);
}

.manage-feeds-top-button .fas,.manage-feeds-top-button .far {
	font-size: 12px;
	margin-right: 6px;
}

.show-section-button {
	padding: 10px 15px 7px 15px;
	border-radius: 8px;
	text-align: center;
	font-size: 15px;
	color: white;
	font-family: semibold;
	cursor: pointer;
	margin-right: 18px;
	-webkit-transition: ease all .15s;
	transition: ease all .15s;
	background-color: #BBBBBB;
	width: 180px;
	margin-bottom: 20px;
	clear: both;
}

.show-section-button:hover {
	-webkit-transform: scale(0.98);
	        transform: scale(0.98);
}

.show-section-button:active {
	-webkit-transform: scale(0.95);
	        transform: scale(0.95);
}

.users-section-button {
	margin-top: 40px;
	margin-bottom: 10px;
}

.show-section-button .fas {
	font-size: 12px;
	margin-left: 6px;
}

.add-feed-button {
	background-color: #47CE4E;
}

.coverage-toggle-button {
	background-color: #999999;
}

.publish-batch-button {
	background-color: #AAAAAA;
}

.confirm-warning {
	background-color: #E04D4A;
}

.manage-search {
	width: 240px;
	border-radius: 8px;
	font-family: semibold;
	font-size: 15px;
	color: #666666;
	line-height: 1.4em;
	padding: 8px 12px 7px 12px;
	border: 0px;
}

.bottom-feed-button {
	text-align: center;
	color: white;
	padding: 11px 16px 10px 16px;
	display: inline-block;
	font-family: bold;
	font-size: 12px;
	border-radius: 8px;
	cursor: pointer;
	-webkit-transition: ease all .15s;
	transition: ease all .15s;
	margin: 10px 15px 60px 0px;
	clear: both;
	float: left;
}

.bottom-feed-button:hover {
	opacity: .9;
}

.bottom-feed-button .fas, .bottom-feed-button .far {
	font-size: 14px;
	margin-right: 5px;
}

.feed-right-buttons-container {
	float: right;
}

.save-button {
	background-color: #4cd137;
}

.create-feed-button {
	background-color: #BBBBBB;
}

.feature-button {
	background-color: #f9ca24;
}

.delete-button {
	background-color: #e84118;
}

.archive-button {
	background-color: #BBBBBB;
}

.manage-feeds-label {
	clear: both;
	font-family: semibold;
	margin-bottom: -10px;
	font-size: 18px;
	color: #333333;
}

.notify-submit-button {
	margin-top: 13px !important;
}

.notify-warning-button {
	background-color: #ff4d4d !important;
}

.feed-gif-message {
	font-family: semibold;
	color: red;
	margin-left: 3px;
	padding-top: 0px;
	margin-bottom: 12px;
	clear: both;
}

.notify-warning-button .fas {
	font-size: 11px;
	margin-right: 5px;
}

.react-tagsinput {
  background-color: #fff !important;
  border-radius: 10px !important;
  width: 550px !important;
	border: 0px !important;
  overflow: hidden !important;
  padding-left: 10px !important;
  padding-top: 10px !important;
}

.react-tagsinput-tag {
  background-color: #F2F2F2 !important;
  border-radius: 2px !important;
  border: 2px solid #eeeeee !important;
  color: #999999 !important;
  display: inline-block !important;
  font-family: semibold !important;
  font-size: 15px !important;
  border-radius: 8px !important;
  font-weight: 400 !important;
  margin-bottom: 10px !important;
  margin-right: 10px !important;
  padding: 5px 9px 2px 9px !important;
}

.react-tagsinput-remove {
  cursor: pointer !important;
}

.react-tagsinput-tag a::before {
  content: " \D7" !important;
}

.react-tagsinput-input {
  background: transparent !important;
  border: 0 !important;
  color: #777 !important;
  font-family: semibold !important;
  font-size: 16px !important;
	color: #333333 !important;
  margin-bottom: 6px !important;
  margin-top: 1px !important;
  outline: none !important;
  padding: 2px 5px 5px 0px !important;
  width: 140px !important;
}

.coverage-buttons-container {
	display: -webkit-flex;
	display: flex;
	margin-bottom: 40px;
}

.coverage-button {
	width: 90px;
	background-color: white;
	border-radius: 8px;
	text-align: center;
	padding-top: 17px;
	padding-bottom: 12px;
	color: #757575;
	font-size: 15px;
	margin-right: 15px;
	cursor: pointer;
}

.coverage-button i {
	width: 100%;
}

.coverage-button:hover {
	-webkit-transform: scale(0.98);
	        transform: scale(0.98);
}

.coverage-button:active {
	-webkit-transform: scale(0.95);
	        transform: scale(0.95);
}

.selected-coverage {
	background-color: #888888;
	color: white;
}

.tooltip-container {
	padding-bottom: 10px !important;
}

.emoji-section-label {
	clear: both;
}

.feed-tile-previews {
	width: 385px;
	display: block;
}

.feed-tile-preview {
	margin-top: 12px;
	width: 180px;
	border-radius: 12px;
	margin-bottom: 30px;
	padding-bottom: 5px;
}

.left-preview {
	float: left;
}

.right-preview {
	float: right;
}

.tile-preview-image-container img {
	width: 180px;
	height: 55px;
	object-fit: cover;
	border-radius: 12px 12px 0px 0px;
	margin-bottom: 8px;
}

.tile-preview-description {
	color: white;
	opacity: .8;
	font-family: semibold;
	font-size: 13px;
	margin: 10px 12px -6px 12px;
	max-height: 49px;
	overflow: hidden;
}

.feed-tile-metadata {
	font-size: 13px;
	font-family: semibold;
	margin-top: 10px;
	color: white;
	font-weight: 500;
	opacity: .8;
	margin-left: 12px;
	-webkit-user-select: none;
	   -moz-user-select: none;
	    -ms-user-select: none;
	        user-select: none;
}

.feed-tile-metadata .fas {
	margin-right: 4px;
	font-size: 10px;
}

.feed-tile-metadata .fa-sync-alt {
	margin-left: 7px;
}

.manage-feed-emoji {
	vertical-align: top;
	display: inline-block;
	margin-top: -6px;
	margin-right: 5px;
}

.users-container {
	margin-left: 340px;
	padding-top: 50px;
}

.users-list-container {
	width: calc(100% - 450px);
	float: left;
	border-radius: 8px;
	padding: 0px 0px 20px 2px;
	margin-bottom: 40px;
	margin-top: 0px;
}

.user-container {
	width: calc(100% - 25px);
	background-color: white;
	border-radius: 8px;
	margin-top: 20px;
	height: auto;
	padding: 15px 20px 9px 6px;
	overflow: hidden;
}

.user-left-container {
	float: left;
}

.user-right-container {
	float: right;
}

.users-top-container {
	margin-bottom: 30px;
}

.users-right-container {
	position: fixed;
	top: 0px;
	width: 310px;
	height: 100%;
	right: 0px;
	padding: 40px;
	background-color: white;
}

.manage-user-button {
	float: right;
	background-color: #CCCCCC;
	border-radius: 8px;
	margin-top: -7px;
	margin-bottom: 3px;
	text-align: center;
	color: white;
	font-size: 15px;
	font-family: semibold;
	padding: 7px 14px 5px 14px;
}

.manage-user-button:hover {
	opacity: .6;
}

.delete-user-button {
	background-color: #e74c3c;
	margin-left: 15px;
}

.user-details-container {
	float: left;
	margin: 6px 0px 3px 10px;
	clear: both;
	font-family: semibold;
	color: #999999;
	font-size: 15px;
}

.bold-text {
	color: #333333;
}

.user-name {
	color: #333333;
	font-size: 17px;
	margin-left: 12px;
	font-family: semibold;
}

.user-role {
	font-family: bold;
	font-size: 11px;
	display: inline;
	border-radius: 8px;
	padding: 7px 8px 5px 8px;
	background-color: #DFDFDF;
	color: #999999;
	margin-left: 12px;
	vertical-align: top;
	line-height: 1.7em;
}

.user-username {
	margin-left: 5px;
	color: #BBBBBB;
	font-family: semibold;
}

.user-password {
	margin-left: 10px;
	color: #CCCCCC;
	font-size: 14px;
	font-family: semibold;
}

.user-leaderboard-item {
	width: calc(100% - 30px);
	padding: 12px 15px 12px 15px;
	background-color: #F5F5F5;
	border-radius: 8px;
	margin-top: 10px;
	position: relative;
	margin-bottom: 15px;
	font-family:semibold;
}

.leaderboard-score {
	float: right;
	color: #BBBBBB;
	font-family: bold;
}

.users-search {
	width: 240px;
	border-radius: 8px;
	font-family: semibold;
	font-size: 15px;
	color: #666666;
	line-height: 1.4em;
	padding: 8px 12px 7px 12px;
	border: 0px;
}

.manage-admin-button {
	float: left;
	padding-top: 10px;
	padding-bottom: 7px;
	border-radius: 8px;
	text-align: center;
	font-size: 15px;
	color: white;
	font-family: semibold;
	background-color: #47CE4E;
	cursor: pointer;
	margin-right: 20px;
}

.create-admin-button {
	width: 95px;
}

.promote-admin-button {
	width: 111px;
}

.manage-admin-button:hover {
	opacity: .8;
}

.manage-admin-button .fa-plus {
	font-size: 13px;
	margin-right: 5px;
}

.login-background {
	position: fixed;
	top: 0px;
	right: 0px;
	left: 0px;
	bottom: 0px;
	background-color: white;
}

.login-container {
	width: 370px;
	position: fixed;
	top: 50%;
	left: 50%;
	margin-top: -240px;
	margin-left: -185px;
	z-index: 1001;
	background-color: white;
	border-radius: 12px;
}

.login-logo {
	height: 32px;
	margin-right: 1px;
	margin-top: -3px;
	float: left;
}

.login-title {
	font-size: 24px;
	margin-top: 8px;
	font-family: semibold;
}

.beta-text, .version-label {
	font-size: 14px;
	color: #999999;
	font-family: semibold;
	margin-left: 4px;
}

.login-top-container {
	display: block;
	margin: 0px auto;
	width: 146px;
	margin-bottom: 50px;
}

.login-container input {
	width: 60%;
	display: block;
	font-family: semibold;
	font-size: 16px;
	color: #333333;
	line-height: 1.4em;
	border-radius: 8px;
	padding: 9px 0px 8px 12px;
	margin: 0px auto;
	margin-top: 20px;
	margin-bottom: 14px;
	border: 0px solid #DFDFDF;
	background-color: #F3F3F3;
	height: 20px;
}

.login-container input:focus {
	outline: 0;
}

.facebook-login-button {
	width: 63%;
	display: block;
	font-family: semibold;
	font-size: 16px;
	color: white;
	border-radius: 8px;
	padding: 11px 17px 27px 17px;
	margin: 0px auto;
	margin-top: 20px;
	border: 0px;
	cursor: pointer;
	margin-bottom: 14px;
	background-color: #4267B2;
	height: 20px;
	-webkit-transition: ease all .1s;
	transition: ease all .1s;
}

.facebook-login-button:hover {
	-webkit-transform: scale(0.98);
	        transform: scale(0.98);
}

.facebook-login-button:active {
	-webkit-transform: scale(0.95);
	        transform: scale(0.95);
}

.google-login-button {
	width: 63%;
	display: block;
	font-family: semibold;
	font-size: 16px;
	color: white;
	border-radius: 8px;
	padding: 11px 17px 27px 17px;
	margin: 0px auto;
	margin-top: 20px;
	border: 0px;
	cursor: pointer;
	margin-bottom: 14px;
	background-color: #CA4130;
	height: 20px;
	-webkit-transition: ease all .1s;
	transition: ease all .1s;
}

.google-login-button:hover {
	-webkit-transform: scale(0.98);
	        transform: scale(0.98);
}

.google-login-button:active {
	-webkit-transform: scale(0.95);
	        transform: scale(0.95);
}

.sms-login-button, .username-login-button {
	width: 63%;
	display: block;
	font-family: semibold;
	font-size: 16px;
	color: white;
	border-radius: 8px;
	padding: 11px 17px 27px 17px;
	margin: 0px auto;
	margin-top: 20px;
	border: 0px;
	cursor: pointer;
	margin-bottom: 14px;
	background-color: #333333;
	height: 20px;
	-webkit-transition: ease all .1s;
	transition: ease all .1s;
}

.username-login-button {
	background-color: #FF7054;
}

.sms-login-button:hover {
	-webkit-transform: scale(0.98);
	        transform: scale(0.98);
}

.sms-login-button:active {
	-webkit-transform: scale(0.95);
	        transform: scale(0.95);
}

.login-submit-button {
	text-align: center;
	width: 80px;
	color: white;
	font-family: semibold;
	padding: 8px 17px 6px 14px;
	font-size: 15px;
	border-radius: 12px;
	background-color: #00a8ff;
	cursor: pointer;
	display: block;
	margin: 0px auto;
	margin-bottom: 30px;
	margin-top: 30px;
	border-radius: 50px;
}

.login-submit-button:hover {
	opacity: .9;
}

.login-container .fas {
	margin-right: 8px;
	font-size: 13px;
}

.manage-updates-top-container {
	float: left;
	margin-left: 370px;
	margin-top: 50px;
	margin-bottom: 55px;
}

.manage-updates-container {
	width: 48%;
	margin-left: 370px;
	clear: both;
	padding-bottom: 80px;
}

.reviewing-updates-container {
	width: 55%;
	margin-left: 370px;
	padding-top: 80px;
	clear: both;
	padding-bottom: 80px;
}

.reviewing-updates-container .feed-slug {
	margin-left: 0px;
}

.reviewing-updates-container .update {
	margin-top: 15px;
	margin-bottom: 40px;
}

.update-approve-button {
	color: white !important;
	background-color: #47CE4E !important;
	font-family: bold !important;
}

.update-reject-button {
	color: white !important;
	background-color: #e84118 !important;
	font-family: bold !important;
}

.update-in-review-button {
	color: white !important;
	background-color: orange !important;
	font-family: bold !important;
}

.update-in-review-button:hover {
	opacity: 1 !important;
}

.needs-source-button {
	color: white !important;
	background-color: red !important;
	font-family: bold !important;
}

.unpublished-label {
	color: white !important;
	background-color: #CCCCCC !important;
	font-family: bold !important;
}

.add-update-button {
	float: left;
	padding: 10px 16px 7px 16px;
	border-radius: 8px;
	text-align: center;
	font-size: 15px;
	color: white;
	font-family: semibold;
	background-color: #47CE4E;
	cursor: pointer;
	margin-right: 20px;
}

.add-update-button:hover {
	opacity: .8;
}

.add-update-button .fa-plus {
	font-size: 12px;
	margin-right: 5px;
}

.publish-feed-batch-button {
	float: left;
	padding: 10px 16px 7px 16px;
	border-radius: 8px;
	text-align: center;
	font-size: 15px;
	color: white;
	font-family: semibold;
	background-color: #999999;
	cursor: pointer;
	margin-right: 20px;
}

.publish-feed-batch-button:hover {
	opacity: .8;
}

.publish-feed-batch-button .fa-clock {
	font-size: 12px;
	margin-right: 5px;
}

.update-container {
	background-color: white;
	font-family: semibold;
	padding: 10px 12px 5px 15px;
	line-height: 1.4em;
	border-radius: 12px;
	border: 0px solid #DFDFDF;
	color: #333333;
	overflow: auto;
	/* position: relative; */
	/* box-shadow: 0px 3px 15px rgba(0,0,0,0.04); */
}

.update-container .fa-clock {
	float: left;
	font-size: 13px;
	margin-top: 8px;
	margin-left: 0px;
	color: #CCCCCC;
}

.update-time {
	float: left;
	font-family: semibold;
	font-size: 13px;
	margin-top: 6px;
	color: #CCCCCC;
}

.update-id {
	float: left;
	font-family: courier;
	font-weight: bold;
	font-size: 13px;
	margin-top: 5px;
	color: #CCCCCC;
	margin-left: 8px;
}

.update {
	margin-top: 35px;
	-webkit-transition: ease all .1s !important;
	transition: ease all .1s !important;
}

.update-expanded .update-container {
	padding-top: 10px;
	-webkit-transition: ease all .15s !important;
	transition: ease all .15s !important;
}

.update-expand {
	float: right;
	font-family: semibold;
	font-size: 14px;
	margin-top: 5px;
	margin-right: 3px;
	cursor: pointer;
	color: #CCCCCC;
	/* position: absolute;
	bottom: 6px;
	right: 12px; */
}

.update-expand .fas {
	margin-left: 4px;
	font-size: 11px;
}

.update-expand:hover {
	opacity:.6;
}

.update-media-caption {
	font-family: semibold;
	padding-top: 7px;
	margin: 0 auto;
	line-height: 1.4em;
	color: #333333;
	margin-bottom: 2px;
}

.update-image {
	cursor: pointer;
	width: 100%;
	border-radius: 5px;
	margin-top: 5px;
}

.update-image:hover {
	opacity: .92;
}

.update-video {
	width: 100%;
	height: 420px;
	cursor: pointer;
	margin-top: 5px;
}

.update-link-button {
	font-size: 14px;
	background-color: #EEEEEE;
	color: #999999;
	border-radius: 8px;
	padding: 4px 10px 4px 10px;
	margin-top: 6px;
	margin-bottom: 3px;
	cursor: pointer;
	display: inline-block;
}

.update-link-button .fa-chevron-right {
	font-size: 11px;
	margin-left: 5px;
}

.update-link-button:hover {
	opacity: .8;
}

.update-link-text {
	margin-top: 7px;
}

.update-twitter-container {
	width: 523px;
}

.update-quote-details {
	background-color: #EFEFEF;
	display: inline-block;
	padding: 4px 6px 3px 10px;
	border-radius: 12px;
	margin-top: 7px;
	cursor: pointer;
}

.update-quote-source {
	float: left;
	margin-right: 4px;
	font-size: 14px;
	margin-top: 2px;
	margin-left: 7px;
	color: #888888;
}

.quote-source-preview {
	margin-top: 4px;
}

.update-quote-details .far {
	margin-right: 5px;
	margin-left: -5px;
}

.update-question-text {
	display: inline-block;
	font-size: 15px;
	background-color: #EEEEEE;
	color: #666666;
	border-radius: 8px;
	padding: 6px 12px 3px 13px;
	margin-top: 3px;
	margin-bottom: 9px;
	opacity: .8;
}

.update-bottom-container .update-reacts {
	font-family: semibold;
	font-size: 14px;
	margin-top: 5px;
	margin-right: 4px;
	color: #e67e22;
	/* color: #999999; */
	cursor: pointer;
	float: left;
	opacity: .5;
}

.update-bottom-container .update-reacts-count {
	margin-right: 8px;
}

.update-bottom-container .update-reacts:hover {
	opacity: .6;
}

.update-bottom-container .dark-reacts {
	opacity: 1;
}

.update-bottom-container .far {
	margin-right: 4px;
	font-size: 14px;
}

.update-expansion-container {
	width: 100%;
	height: 32px;
	margin-top: 35px;
	/* background-color: #F2F2F2; */
	/* margin-left: -15px; */
	/* padding: 0px 15px 14px 15px; */
}

.update-expansion-left-container {
	width: 43%;
	float: left;
	height: 32px;
	display: -webkit-flex;
	display: flex;
}

.update-react-button {
	-webkit-flex-grow: 1;
	        flex-grow: 1;
	background-color: #EFEFEF;
	color: white;
	margin-right: 7px;
	border-radius: 8px;
	font-size: 14px;
	height: 24px;
	margin-top: 3px;
	text-align: right;
	padding: 4px 8px 0px 9px;
	overflow: auto;
}

.update-react-button .fas {
	margin-right: 3px;
	font-size: 14px;
	float: left;
	margin-top: 3px;
}

.update-react-button .fa-thumbs-up {
	font-size: 13px;
	vertical-align: top;
	margin-top: 3px;
	float: left;
}

.yellow-react {
	background-color: #fbc531
}

.red-react {
	background-color: #e84118;
}

.blue-react {
	background-color: #00a8ff;
}

.purple-react {
	background-color: #9c88ff;
}

.green-react {
	background-color: #4cd137;
}

[data-reacted="true"] {
	opacity: .5;
}

.update-expansion-center-container {
	width: calc(41% - 16px);
	height: 32px;
	float: left;
	padding-left: 8px;
	padding-right: 8px;
}

.update-share-button {
	width: 100%;
	text-align: center;
	padding-top: 5px;
	height: 27px;
	border-radius: 8px;
	background-color: #1FA0F2;
	color: white;
}

.update-share-button .fab {
	font-size: 14px;
	margin-right: 5px;
}

.update-expansion-right-container {
	width: calc(16% - 8px);
	float: right;
	height: 32px;
	padding-left: 8px;
}

.update-source-button {
	font-size: 15px;
	background-color: #EEEEEE;
	color: #999999;
	border-radius: 8px;
	padding: 6px 10px 4px 10px;
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
}

.update-source-button i {
	float: left;
	margin-right: 5px;
	font-size: 13px;
	margin-top: 3px;
}

.update-source-button:hover, .update-share-button:hover {
	opacity: .7;
}

[data-reacted="true"]:hover {
	opacity: .5!important;
}

.update-top-container {
	margin-bottom: 0px;
}

.manage-update-button {
	background-color: #CCCCCC;
	font-size: 13px;
	border-radius: 8px;
	width: auto;
	text-align: center;
	display: inline-block;
	color: white;
	font-family: semibold;
	padding: 2px 12px 0px 12px;
	margin: 0px 8px 0px 0px;
	cursor: pointer;
}

.update-edit-button {
	float: left;
}

.hidden-update {
	opacity: .5;
}

.quote-profile-image {
	width: 22px;
	height: 22px;
	border-radius: 11px;
	float: left;
	margin-top: 2px;
	margin-bottom: 2px;
	margin-left: -2px;
}

.importance-label {
	color: red;
	font-size: 15px;
	margin-top: 10px;
	margin-bottom: 6px;
}

.importance-label span {
	font-size: 12px;
	margin-right: 4px;
}

.manage-updates-container .importance-label {
	margin-bottom: -5px;
}

.notify-confirm-button {
	background-color: red !important;
}

.notify-confirm-button .fas {
	font-size: 11px;
	margin-right: 6px;
}

.feed-published-banner {
	width: calc(100% - 15px);
	padding-top: 12px;
	padding-bottom: 10px;
	background-color: #47CE4E;
	color: white;
	font-size: 16px;
	margin-top: 30px;
	font-family: semibold;
	padding-left: 15px;
	border-radius: 8px;
}

.feed-published-banner .far {
	font-size: 15px;
	margin-right: 7px;
}

.feed-archived-banner {
	width: calc(100% - 15px);
	padding-top: 12px;
	padding-bottom: 10px;
	background-color: #F34545;
	color: white;
	font-size: 16px;
	margin-top: 30px;
	font-family: semibold;
	padding-left: 15px;
	border-radius: 8px;
}

.feed-archived-banner .fas {
	font-size: 15px;
	margin-right: 7px;
}

.unpublished-updates-banner {
	width: calc(100% - 15px);
	padding-top: 12px;
	padding-bottom: 10px;
	background-color: #999999;
	color: white;
	font-size: 16px;
	margin-top: 30px;
	font-family: semibold;
	padding-left: 15px;
	border-radius: 12px;
}

.unpublished-updates-banner .fas {
	font-size: 15px;
	margin-right: 7px;
}

.feed-updates-separator {
	width: 100%;
	height: 2px;
	background-color: #dddddd;
	margin-top: -10px;
	margin-bottom: 42px;
}

.feed-update-container {
	height: 56px;
	width: 400px;
	border-radius: 8px;
	margin-top: 10px;
	margin-bottom: 5px;
	padding-top: 14px;
	-webkit-transition: ease all .15s;
	transition: ease all .15s;
}

.feed-update-container:hover {
	-webkit-transform: scale(0.99);
	        transform: scale(0.99);
}

.feed-update-container:active {
	-webkit-transform: scale(0.98);
	        transform: scale(0.98);
}

.feed-update-image {
	width: 70px;
	height: 70px;
	float:left;
	border-radius: 8px 0px 0px 8px;
	margin-top: -14px;
	object-fit: cover;
	margin-right: 2px;
}

.feed-update-details {
	color: white;
	font-size: 13px;
	margin-top: 7px;
	opacity: .7;
}

.feed-update-details i {
	font-size: 11px;
	margin-left: 9px;
	margin-right: 3px;
}

.feed-update-button {
	float: right;
	margin-top: -40px;
	margin-right: 15px;
	color: white;
	border-radius: 20px;
	opacity: .7;
}

.feed-update-container .feed-slug {
	margin-left: 10px;
}

.update-preview-container .feed-update-container {
	width: 100%;
}

.update-preview-container .feed-update-container .feed-slug {
	margin-top: -2px;
	padding-left: 9px;
	padding-bottom: 3px;
	padding-top: 4px;
	font-size: 15px;
	margin-left: 8px;
	display: inline-block;
}

.update-preview-container .feed-update-container .feed-update-details {
	margin-top: 8px !important;
}

.update-preview-container .feed-update-container .feed-update-button {
	margin-top: -38px;
}

.custom-tooltip {
	font-family: semibold;
	font-size: 15px;
	width: 300px;
	border-radius: 8px;
	line-height: 1.3em;
	padding-top: 10px;
}

.toggle-background-button {
	background-color: #DDDDDD;
	display: inline-block;
	cursor: pointer;
	color: #888888;
	font-family: semibold;
	padding: 10px 12px 7px 12px;
	border-radius: 8px;
	-webkit-transition: ease all .1s;
	transition: ease all .1s;
}

.fetch-all-button {
	margin-left: 20px;
}

.toggle-background-button:hover {
	-webkit-transform: scale(0.98);
	        transform: scale(0.98);
}

.toggle-background-button:active {
	-webkit-transform: scale(0.95);
	        transform: scale(0.95);
}

.toggle-background-button .fas {
	margin-right: 7px;
	font-size: 15px;
}

.toggle-background-button .queue-count {
	background-color: red;
	color: white;
	font-family: bold;
	font-size: 14px;
	padding-right: 5px;
	padding-left: 5px;
	padding-top: 3px;
	vertical-align: top;
	margin-left: 6px;
}

.update-text-container {
	clear: both;
	padding-top: 9px;
}

.queued-update-updates-container .update-text-container {
	padding-top: 0px;
}

.queue-right-container .update-text-container {
	padding-top: 0px !important;
}

.draft-right-container .update-text-container {
	padding-top: 0px !important;
}

.reviewing-update-author {
	font-size: 14px !important;
	color: #999999 !important;
	margin-left: 6px;
}

.update-bottom-crosspost-container {
	width: calc(100% - 22px);
	display: inline-block;
	/* background-color: #BBBBBB; */
	clear: both;
	color: white;
	border-radius: 8px;
	padding: 5px 10px 3px 12px;
	font-size: 15px;
	margin-bottom: 10px;
	margin-top: 7px;
	-webkit-transition: ease all .1s;
	transition: ease all .1s;
}

.update-bottom-crosspost-container:hover {
	-webkit-transform: scale(0.99);
	        transform: scale(0.99);
}

.update-bottom-crosspost-container:active {
	-webkit-transform: scale(0.97);
	        transform: scale(0.97);
}

.update-bottom-crosspost-container i {
	float: right;
	padding-top: 4px;
	font-size: 14px;
}

.crosspost-id {
	font-family: courier;
	font-weight: bold;
	font-size: 14px;
}

.update-notes-container {
	background-color: #ffd32a;
	color: #555555;
	font-size: 15px;
	padding: 5px 9px 2px 9px;
	margin-top: 5px;
	margin-bottom: 5px;
	border-radius: 5px;
	display: inline-block;
}

.rejected-updates-header, .pending-updates-header {
	font-family: semibold;
	background-color: #DDDDDD;
	padding: 7px 0px 7px 0px;
	border-radius: 8px;
	width: 170px;
	text-align: center;
	color: #888888;
	margin-bottom: 30px;
}

.rejected-updates-header {
	margin-top: 80px;
}

.rejected-updates-container .fas, .pending-updates-container .fas {
	margin-top: 3px;
	margin-right: 7px;
}

.rejected-updates-container {
	margin-top: 80px;
}

.update-article-container {
	background-color: #f3f3f3;
	border-radius: 8px;
	padding: 7px 20px 15px 18px;
	margin-bottom: 10px;
	margin-top: 15px;
	-webkit-transition: ease all 0.2s;
	transition: ease all 0.2s;
}

.update-article-container:hover {
	-webkit-transform: scale(0.99);
	        transform: scale(0.99);
}

.update-article-container .article-update-title {
	font-size: 16px;
	margin-top: 6px;
}

.update-article-container .article-update-site {
	font-size: 14px;
	margin-top: 2px;
}

.update-article-container-preview {
	background-color: #f3f3f3;
	border-radius: 8px;
	padding: 10px 16px 16px 14px;
	margin-bottom: 10px;
}

.article-update-image {
	width: 50px;
	height: 50px;
	float: left;
	border-radius: 8px;
	margin-right: 10px;
}

.article-update-favicon {
	width: 16px;
	height: 16px;
	float: left;
	border-radius: 8px;
	margin-right: 8px;
	margin-top: 3px;
}

.article-update-title {
	font-family: bold;
	color: #333333;
	font-size: 14px;
	line-height: 1.3em;
	margin-top: 2px;
}

.article-update-site {
	font-family: semibold;
	color: #9D9D9D;
	font-size: 12px;
	padding-top: 2px;
	white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
	margin-bottom: -7px;
}

.update-article-container-preview .article-update-site {
	padding-top: 4px;
}

.update-article-container-preview .article-update-favicon {
	margin-top: 4px;
}

.article-caption-text {
	margin-left: 2px;
}

.batch-container {
	width: 820px;
	margin-left: 340px;
	padding-bottom: 80px;
	padding-top: 50px;
}

.countdown-container {
	padding: 9px 15px 7px 15px;
	border-radius: 8px;
	text-align: center;
	font-size: 17px;
	color: white;
	font-family: semibold;
	margin-right: 18px;
	-webkit-transition: ease all .15s;
	transition: ease all .15s;
	background-color: #E04D4A;
}

.batch-updates-container {
	width: 100%;
	clear: both;
	padding-bottom: 80px;
	margin-top: 10px;
}

.batch-top-container {
	float: left;
	display: -webkit-flex;
	display: flex;
}

.batch-separator {
	width: 860px;
	height: 2px;
	background-color: #DDDDDD;
	margin-top: 70px;
}

.batch-updates-container .feed-slug {
	margin-left: 0px;
	display: inline-block;
	margin-top: 32px;
	padding-bottom: 3px;
}

.batch-updates-container .update {
	margin-top: 22px;
}

.clear-current-button {
	background-color: #AAAAAA;
}

@media only screen and (max-width: 1530px) {
  .manage-updates-top-container {
  	margin-left: 335px;
  }
  .manage-updates-container {
  	margin-left: 335px;
  }
}

/* iPad Pro 11-inch changes */
@media only screen and (max-width: 1440px) {
  .queue-right-container {
		display: none;
	}
	.queue-left-container {
		width: 100%;
	}
  .manage-feeds-container {
    width: calc(100% - 190px);
  }
  .feeds-container {
    margin-left: 315px;
  }
  .feed-details-label {
    float: left;
    clear: both;
    margin-top: 12px;
    margin-bottom: 7px;
  }
  .managed-feed {
    padding-top: 13px;
  }
  .manage-feed-button {
    padding-left: 8px;
    padding-right: 8px;
  }
  .manage-feed-description {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
  }
  .feeds-right-container {
    width: 250px;
  }
  .feed-slug {
    font-size: 15px;
    padding-top: 6px;
    padding-bottom: 3px;
  }
  .feeds-container .feed-slug {
    float: left;
  }
  .user-leaderboard-item {
  	width: calc(100% - 30px);
  	padding: 10px 12px 12px 11px !important;
  	margin-top: 5px;
  	margin-bottom: 14px;
  }
  .leaderboard-score {
    padding-top: 7px;
    font-size: 14px;
  }
  .update-stopwatch-container {
    display: none;
  }
  .draft-right-container {
    width: 270px;
    padding-left: 25px;
    padding-right: 25px;
  }
  .draft-right-container .update-text-container {
    font-size: 14px;
  }
  .draft-container {
    margin-left: 300px;
  }
  .draft-left-container {
    width: calc(100% - 290px);
  }
  .faq-checkbox-container {
    float: left;
    clear: both;
    margin-top: 13px;
  }
  .faq-crosspost-container {
    float: left;
    margin-top: 13px;
  }
}

@media only screen and (max-width: 1180px) {
	.queue-container {
		width: calc(100% - 415px);
	}
}

@media only screen and (max-width: 1000px) {
  .mobile-menu-button {
    position: fixed;
    display: block;
    top: 20px;
    left: 28px;
    z-index: 999999;
  }
  .queue-top-container {
    margin-top: 23px;
  }
  .manage-feeds-top-button {
    padding-right: 10px;
    padding-left: 12px;
    font-size: 12px;
    margin-right: 9px;
  }
  .sidebar-top-container {
    margin-top: 40px;
  }
  .sidebar-title {
    display: none;
  }
  .sidebar-container {
    z-index: 99999;
  }
  .queue-container {
    margin-left: 0px;
    width: 90%;
    display: block;
    margin: 0px auto;
  }
  .queued-update-updates-container {
    display: none;
  }
  .queued-update-tweet-container {
    width: 100%;
    margin-left: -12px;
    margin-top: -5px;
  }
  .update-scout-label {
    display: none;
  }
  .queued-update .feed-slug {
    font-size: 14px;
  }
  .queue-claim-button {
    font-size: 12px;
    width: 55px;
  }
  .queue-reject-button {
    width: 55px;
    text-align: center;
    font-size: 12px;
    padding-left: 0px;
    padding-right: 0px;
    margin-right: 8px;
  }
  .queue-swap-button {
    width: 24px;
    font-size: 12px;
  }
  .draft-container {
    margin-left: 15px;
    margin-right: 15px;
  }
  .draft-right-container {
    display: none;
  }
  .draft-left-container {
    width: 100%;
    margin-top: 40px;
    float: none;
  }
  .update-info-container {
    width: 100%;
  }
  .update-info-right {
    display: none;
  }
  .draft-action-button {
    font-size: 12px;
  }
  .draft-container .feed-slug {
    font-size: 13px;
  }
  .draft-mobile-source {
    display: block;
  }
  .manage-updates-top-container {
  	margin-left: 20px;
  }
  .manage-updates-container {
  	margin-left: 20px;
    width: 90%;
  }
  .feed-buttons-container {
    width: calc(100% - 15px);
  }
  .queue-label {
    display: none;
  }
  .mobile-leaderboard-container {
    display: none;
  }
  .feeds-container {
    width: calc(100% - 40px);
  	margin-left: 20px;
  }
  .manage-feeds-container {
    width: 100%;
  }
}

.data-container {
	margin-left: 340px;
	padding-top: 50px;
}

.data-list-container {
	width: calc(100% - 450px);
	float: left;
	border-radius: 8px;
	padding: 0px 0px 20px 2px;
	margin-bottom: 25px;
	margin-top: 0px;
	font-family: regular;
	font-size: 17px;
}

.data-bold {
	font-family: semibold;
}

.user-container {
	width: calc(100% - 25px);
	background-color: white;
	border-radius: 8px;
	margin-top: 20px;
	height: auto;
	padding: 15px 20px 9px 6px;
	overflow: hidden;
}

.user-left-container {
	float: left;
}

.user-right-container {
	float: right;
}

.data-top-container {
	margin-bottom: 60px;
}

.data-right-container {
	position: fixed;
	top: 0px;
	width: 310px;
	height: 100%;
	right: 0px;
	padding: 40px;
	background-color: white;
}

.manage-user-button {
	float: right;
	background-color: #CCCCCC;
	border-radius: 8px;
	margin-top: -7px;
	margin-bottom: 3px;
	text-align: center;
	color: white;
	font-size: 15px;
	font-family: semibold;
	padding: 7px 14px 5px 14px;
}

.manage-user-button:hover {
	opacity: .6;
}

.delete-user-button {
	background-color: #e74c3c;
	margin-left: 15px;
}

.user-details-container {
	float: left;
	margin: 6px 0px 3px 10px;
	clear: both;
	font-family: semibold;
	color: #999999;
	font-size: 15px;
}

.bold-text {
	color: #333333;
}

.user-name {
	color: #333333;
	font-size: 17px;
	margin-left: 12px;
	font-family: semibold;
}

.user-role {
	font-family: bold;
	font-size: 11px;
	display: inline;
	border-radius: 8px;
	padding: 7px 8px 5px 8px;
	background-color: #DFDFDF;
	color: #999999;
	margin-left: 12px;
	vertical-align: top;
	line-height: 1.7em;
}

.user-username {
	margin-left: 5px;
	color: #BBBBBB;
	font-family: semibold;
}

.user-password {
	margin-left: 10px;
	color: #CCCCCC;
	font-size: 14px;
	font-family: semibold;
}

.user-leaderboard-item {
	width: calc(100% - 30px);
	padding: 12px 15px 12px 15px;
	background-color: #F5F5F5;
	border-radius: 8px;
	margin-top: 10px;
	position: relative;
	margin-bottom: 15px;
	font-family:semibold;
}

.leaderboard-score {
	float: right;
	color: #BBBBBB;
	font-family: bold;
}

.data-search {
	width: 240px;
	border-radius: 8px;
	font-family: semibold;
	font-size: 15px;
	color: #666666;
	line-height: 1.4em;
	padding: 8px 12px 7px 12px;
	border: 0px;
}

.manage-admin-button {
	float: left;
	padding-top: 10px;
	padding-bottom: 7px;
	border-radius: 8px;
	text-align: center;
	font-size: 15px;
	color: white;
	font-family: semibold;
	background-color: #47CE4E;
	cursor: pointer;
	margin-right: 20px;
}

.create-admin-button {
	width: 95px;
}

.promote-admin-button {
	width: 111px;
}

.manage-admin-button:hover {
	opacity: .8;
}

.manage-admin-button .fa-plus {
	font-size: 13px;
	margin-right: 5px;
}

