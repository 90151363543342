.sidebar-container {
	width: 210px;
	position: fixed;
	left: 0px;
	top: 0px;
	bottom: 0px;
	background-color: white;
	padding: 25px 30px 30px 30px;
}

.sidebar-title {
	font-family: semibold;
	font-size: 28px;
}

.sidebar-icon {
	width: 26px;
	margin-top: 3px;
	margin-right: 2px;
	float: left;
}

.current-user {
	font-family: semibold;
	background-color: #DDDDDD;
	display: inline-block;
	margin-top: 7px;
	color: #999999;
	font-size: 14px;
	padding: 5px 10px 4px 10px;
	border-radius: 8px;
	cursor: default;
}

.current-user .fas {
	margin-left: 5px;
	font-size: 13px;
	cursor: pointer;
}

.current-user .fas:hover {
	opacity: .5;
}

.sidebar-button-label {
	margin-left: 15px;
	font-family: semibold;
	font-size: 15px;
}

.sidebar-button-label .fas {
	font-size: 13px;
	margin-right: 10px;
	margin-top: 2px;
}

.sidebar-button-label .fa-bars {
	color: #999999;
}

.sidebar-bottom-container {
	position: absolute;
	bottom: 0px;
	right: 0px;
	left: 0px;
	overflow: auto;
	padding: 20px 30px 22px 30px;
	background-color: white;
}

.sidebar-separator-bottom {
	width: 210px;
	height: 2px;
	background-color: #DDDDDD;
}

.sidebar-bottom-button {
	width: 100%;
	padding-top: 10px;
	padding-bottom: 9px;
	background-color: #DFDFDF;
	border-radius: 8px;
	margin-top: 18px;
	color: black;
	position: relative;
	cursor: pointer;
	transition: ease all .2s;
}

.sidebar-buttons-container {
	padding-top: 15px;
}

.update-queue-button {
	width: 100%;
	padding-top: 10px;
	padding-bottom: 9px;
	background-color: #DFDFDF;
	border-radius: 8px;
	margin-top: 15px;
	color: black;
	position: relative;
	cursor: pointer;
	transition: ease all .2s;
}

.active-sidebar-button .update-queue-button,
.active-sidebar-button .sidebar-bottom-button {
	background-color: #AAAAAA !important;
	color: white;
}

.coverage-paused-button {
	width: 165px;
	padding-top: 9px;
	padding-bottom: 8px;
	background-color: #333333;
	border-radius: 8px;
	margin-top: 15px;
	color: white;
	position: relative;
	user-select: none;
}

.coverage-paused-button .sidebar-button-label {
	font-size: 15px;
}

.coverage-paused-button .fas {
	font-size: 13px;
}

.active-sidebar-button .fas {
	color: white !important;
}

.sidebar-bottom-button .fas {
	color: #999999;
}

.submit-update-button {
	display: inline-block;
	padding: 8px 15px 7px 0px;
	background-color: #47CE4D;
	border-radius: 8px;
	margin-top: 16px;
	color: white;
	position: relative;
	cursor: pointer;
}

.submit-update-button .sidebar-button-label {
	font-size: 15px !important;
}

.submit-update-button .sidebar-button-label .fas {
	font-size: 13px !important;
}

.update-queue-button:hover, .submit-update-button:hover {
	opacity: .8;
}

.update-count {
	position: absolute;
	color: white;
	width: auto;
	padding-left: 6px;
	padding-right: 6px;
	padding-top: 4px;
	padding-bottom: 2px;
	background-color: red;
	border-radius: 13px;
	right: 14px;
	top: 9px;
	font-size: 12px;
	font-family: bold;
}

.sidebar-separator {
	width: 100%;
	height: 2px;
	background-color: #DDDDDD;
	margin-top: 18px;
}

.sidebar-drafts-container {
	margin-top: 10px;
	overflow: scroll;
	height: calc(100% - 508px);
	width: 240px;
}

.sidebar-drafts-container-paused {
	margin-top: 10px;
	overflow: scroll;
	height: calc(100% - 600px);
	width: 240px;
}

.update-draft-container {
	width: 210px;
	padding-top: 12px;
	padding-bottom: 12px;
	background-color: #DFDFDF;
	border-radius: 12px;
	margin-top: 14px;
	color: #929292;
	position: relative;
	cursor: pointer;
	transition: ease all .3s;
}

.active-draft-container .update-draft-container {
	width: 240px;
	border-radius: 12px 0px 0px 12px;
}

.active-draft-container .update-draft-container .fa-chevron-right {
	display:none;
}

.update-draft-container:hover {
	opacity: .8;
}

.active-draft-container .update-draft-container:hover {
	opacity: 1;
}

.feed-slug {
	display: inline;
	margin-left: 9px;
	font-family: semibold;
	color: white;
	padding: 5px 8px 5px 8px;
	border-radius: 20px;
}

.feed-slug .fas {
	font-size: 14px;
	margin-right: 3px;
}

.update-draft-top-container .fa-chevron-right {
	position: absolute;
	right: 12px;
	top: 24px;
	color: white;
}

.update-status-label {
	font-family: bold;
	opacity: .8;
	margin-left: 13px;
	margin-top: 10px;
	margin-bottom: -5px;
	font-size: 12px;
}

.active-status-label {
	color: white;
}

.sidebar-logout-button {
	position: absolute;
	bottom: 35px;
	padding: 15px 15px 14px 14px;
	border-radius: 8px;
	color: #999999;;
	font-size: 16px;
	background-color: #DFDFDF;
	cursor: pointer;
	margin-left: -7px;
}

.sidebar-guide-button {
	position: absolute;
	bottom: 30px;
	width: 198px;
	left: 24px;
	right: 23px;
	background-color: #DFDFDF;
	border-radius: 8px;
	font-size: 16px;
	padding: 13px 13px 13px 10px;
	font-family: semibold;
  overflow: auto;
	cursor: pointer;
}

.sidebar-guide-button:hover {
	opacity: .8;
}

.sidebar-guide-button .fa-book {
	font-size: 16px;
	margin-right: 10px;
	margin-left: 7px;
	color: #999999;
}

.sidebar-guide-button .fa-chevron-right {
	color: #999999;
	position: absolute;
	right: 15px;
	margin-top: 1px;
	font-size: 15px;
}

.sidebar-bottom-button .fa-chevron-right {
	 color: #999999;
 	position: absolute;
 	right: 7px;
 	margin-top: 1px;
 	font-size: 15px;
 }

 .feed-emoji {
	 margin-left: 8px;
 }
