.modal-container {
	display: none;
	width: 420px;
	position: fixed;
	top: 50%;
	left: 50%;
	margin-top: -290px;
	margin-left: -250px;
	z-index: 1001;
	background-color: #F5F5F5;
	border-radius: 12px;
	padding: 20px 40px 20px 40px;
}

.darken {
	position: fixed;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	z-index: 1000;
	background-color: black;
	opacity: .7;
	display: none;
	cursor: pointer;
}

.modal-container input {
	width: calc(100% - 18px);
	display: block;
	font-family: semibold;
	font-size: 16px;
	color: #333333;
	line-height: 1.4em;
	border-radius: 5px;
	padding: 9px;
	margin: 0px auto;
	margin-top: 20px;
	margin-bottom: 14px;
	height: 20px;
	border: 0px;
}

.modal-container .modal-notes-input {
	height: 60px;
}

.feeds-selector {
	font-family: semibold;
	padding-top: 5px;
}

.css-1aya2g8, .css-2o5izw {
	font-family: semibold;
	background-color: white !important;
	border-radius: 5px !important;
	border: 0px !important;
	height: 40px;
	padding-top: -0px !important;
}

.css-2o5izw {
	box-shadow: 0px 0px 0px 1px #999999 !important;
}

.css-rsyb7x {
	height: 32px;
}

.css-rsyb7x input {
	margin-top: 4px;
}

.css-1rtrksz {
	height: 42px;
}

.modal-container textarea {
	width: calc(100% - 18px);
	display: block;
	font-family: semibold;
	font-size: 16px;
	color: #333333;
	line-height: 1.4em;
	border-radius: 5px;
	padding: 9px;
	margin: 0px auto;
	margin-top: 20px;
	margin-bottom: 14px;
	border: 0px;
	height: 85px;
}

.modal-container select {
	border: 0px;
	font-family: semibold;
	background-color: white;
	font-size: 16px;
	-webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  padding: 11px 12px 9px 12px;
	width: 100px;
	margin-top: 4px;
	color: #757575;
}

.modal-container select:focus {
	outline: 0;
}

.submit-input-container .fas {
	margin-left: -18px;
	color: #757575;
	vertical-align: top;
	margin-top: 14px;
}

.submit-input-container select {
	width: 160px;
}

.modal-submit-button {
	text-align: center;
	color: white;
	display: inline-block;
	font-family: bold;
	font-size: 12px;
	padding: 9px 20px 8px 20px;
	border-radius: 12px;
	background-color: #00a8ff;
	cursor: pointer;
	margin-bottom: 15px;
	margin-top: 30px;
}

.modal-submit-button:hover {
	opacity: .9;
}

.modal-container .modal-submit-button {
	background-color: #4cd137;
}

.modal-container .fas {
	margin-right: 5px;
	font-size: 13px;
}

.modal-title {
	font-size: 18px;
	text-align: left;
	font-family: semibold;
}
