.users-container {
	margin-left: 340px;
	padding-top: 50px;
}

.users-list-container {
	width: calc(100% - 450px);
	float: left;
	border-radius: 8px;
	padding: 0px 0px 20px 2px;
	margin-bottom: 40px;
	margin-top: 0px;
}

.user-container {
	width: calc(100% - 25px);
	background-color: white;
	border-radius: 8px;
	margin-top: 20px;
	height: auto;
	padding: 15px 20px 9px 6px;
	overflow: hidden;
}

.user-left-container {
	float: left;
}

.user-right-container {
	float: right;
}

.users-top-container {
	margin-bottom: 30px;
}

.users-right-container {
	position: fixed;
	top: 0px;
	width: 310px;
	height: 100%;
	right: 0px;
	padding: 40px;
	background-color: white;
}

.manage-user-button {
	float: right;
	background-color: #CCCCCC;
	border-radius: 8px;
	margin-top: -7px;
	margin-bottom: 3px;
	text-align: center;
	color: white;
	font-size: 15px;
	font-family: semibold;
	padding: 7px 14px 5px 14px;
}

.manage-user-button:hover {
	opacity: .6;
}

.delete-user-button {
	background-color: #e74c3c;
	margin-left: 15px;
}

.user-details-container {
	float: left;
	margin: 6px 0px 3px 10px;
	clear: both;
	font-family: semibold;
	color: #999999;
	font-size: 15px;
}

.bold-text {
	color: #333333;
}

.user-name {
	color: #333333;
	font-size: 17px;
	margin-left: 12px;
	font-family: semibold;
}

.user-role {
	font-family: bold;
	font-size: 11px;
	display: inline;
	border-radius: 8px;
	padding: 7px 8px 5px 8px;
	background-color: #DFDFDF;
	color: #999999;
	margin-left: 12px;
	vertical-align: top;
	line-height: 1.7em;
}

.user-username {
	margin-left: 5px;
	color: #BBBBBB;
	font-family: semibold;
}

.user-password {
	margin-left: 10px;
	color: #CCCCCC;
	font-size: 14px;
	font-family: semibold;
}

.user-leaderboard-item {
	width: calc(100% - 30px);
	padding: 12px 15px 12px 15px;
	background-color: #F5F5F5;
	border-radius: 8px;
	margin-top: 10px;
	position: relative;
	margin-bottom: 15px;
	font-family:semibold;
}

.leaderboard-score {
	float: right;
	color: #BBBBBB;
	font-family: bold;
}

.users-search {
	width: 240px;
	border-radius: 8px;
	font-family: semibold;
	font-size: 15px;
	color: #666666;
	line-height: 1.4em;
	padding: 8px 12px 7px 12px;
	border: 0px;
}

.manage-admin-button {
	float: left;
	padding-top: 10px;
	padding-bottom: 7px;
	border-radius: 8px;
	text-align: center;
	font-size: 15px;
	color: white;
	font-family: semibold;
	background-color: #47CE4E;
	cursor: pointer;
	margin-right: 20px;
}

.create-admin-button {
	width: 95px;
}

.promote-admin-button {
	width: 111px;
}

.manage-admin-button:hover {
	opacity: .8;
}

.manage-admin-button .fa-plus {
	font-size: 13px;
	margin-right: 5px;
}
