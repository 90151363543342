.manage-updates-top-container {
	float: left;
	margin-left: 370px;
	margin-top: 50px;
	margin-bottom: 55px;
}

.manage-updates-container {
	width: 48%;
	margin-left: 370px;
	clear: both;
	padding-bottom: 80px;
}

.reviewing-updates-container {
	width: 55%;
	margin-left: 370px;
	padding-top: 80px;
	clear: both;
	padding-bottom: 80px;
}

.reviewing-updates-container .feed-slug {
	margin-left: 0px;
}

.reviewing-updates-container .update {
	margin-top: 15px;
	margin-bottom: 40px;
}

.update-approve-button {
	color: white !important;
	background-color: #47CE4E !important;
	font-family: bold !important;
}

.update-reject-button {
	color: white !important;
	background-color: #e84118 !important;
	font-family: bold !important;
}

.update-in-review-button {
	color: white !important;
	background-color: orange !important;
	font-family: bold !important;
}

.update-in-review-button:hover {
	opacity: 1 !important;
}

.needs-source-button {
	color: white !important;
	background-color: red !important;
	font-family: bold !important;
}

.unpublished-label {
	color: white !important;
	background-color: #CCCCCC !important;
	font-family: bold !important;
}

.add-update-button {
	float: left;
	padding: 10px 16px 7px 16px;
	border-radius: 8px;
	text-align: center;
	font-size: 15px;
	color: white;
	font-family: semibold;
	background-color: #47CE4E;
	cursor: pointer;
	margin-right: 20px;
}

.add-update-button:hover {
	opacity: .8;
}

.add-update-button .fa-plus {
	font-size: 12px;
	margin-right: 5px;
}

.publish-feed-batch-button {
	float: left;
	padding: 10px 16px 7px 16px;
	border-radius: 8px;
	text-align: center;
	font-size: 15px;
	color: white;
	font-family: semibold;
	background-color: #999999;
	cursor: pointer;
	margin-right: 20px;
}

.publish-feed-batch-button:hover {
	opacity: .8;
}

.publish-feed-batch-button .fa-clock {
	font-size: 12px;
	margin-right: 5px;
}

.update-container {
	background-color: white;
	font-family: semibold;
	padding: 10px 12px 5px 15px;
	line-height: 1.4em;
	border-radius: 12px;
	border: 0px solid #DFDFDF;
	color: #333333;
	overflow: auto;
	/* position: relative; */
	/* box-shadow: 0px 3px 15px rgba(0,0,0,0.04); */
}

.update-container .fa-clock {
	float: left;
	font-size: 13px;
	margin-top: 8px;
	margin-left: 0px;
	color: #CCCCCC;
}

.update-time {
	float: left;
	font-family: semibold;
	font-size: 13px;
	margin-top: 6px;
	color: #CCCCCC;
}

.update-id {
	float: left;
	font-family: courier;
	font-weight: bold;
	font-size: 13px;
	margin-top: 5px;
	color: #CCCCCC;
	margin-left: 8px;
}

.update {
	margin-top: 35px;
	transition: ease all .1s !important;
}

.update-expanded .update-container {
	padding-top: 10px;
	transition: ease all .15s !important;
}

.update-expand {
	float: right;
	font-family: semibold;
	font-size: 14px;
	margin-top: 5px;
	margin-right: 3px;
	cursor: pointer;
	color: #CCCCCC;
	/* position: absolute;
	bottom: 6px;
	right: 12px; */
}

.update-expand .fas {
	margin-left: 4px;
	font-size: 11px;
}

.update-expand:hover {
	opacity:.6;
}

.update-media-caption {
	font-family: semibold;
	padding-top: 7px;
	margin: 0 auto;
	line-height: 1.4em;
	color: #333333;
	margin-bottom: 2px;
}

.update-image {
	cursor: pointer;
	width: 100%;
	border-radius: 5px;
	margin-top: 5px;
}

.update-image:hover {
	opacity: .92;
}

.update-video {
	width: 100%;
	height: 420px;
	cursor: pointer;
	margin-top: 5px;
}

.update-link-button {
	font-size: 14px;
	background-color: #EEEEEE;
	color: #999999;
	border-radius: 8px;
	padding: 4px 10px 4px 10px;
	margin-top: 6px;
	margin-bottom: 3px;
	cursor: pointer;
	display: inline-block;
}

.update-link-button .fa-chevron-right {
	font-size: 11px;
	margin-left: 5px;
}

.update-link-button:hover {
	opacity: .8;
}

.update-link-text {
	margin-top: 7px;
}

.update-twitter-container {
	width: 523px;
}

.update-quote-details {
	background-color: #EFEFEF;
	display: inline-block;
	padding: 4px 6px 3px 10px;
	border-radius: 12px;
	margin-top: 7px;
	cursor: pointer;
}

.update-quote-source {
	float: left;
	margin-right: 4px;
	font-size: 14px;
	margin-top: 2px;
	margin-left: 7px;
	color: #888888;
}

.quote-source-preview {
	margin-top: 4px;
}

.update-quote-details .far {
	margin-right: 5px;
	margin-left: -5px;
}

.update-question-text {
	display: inline-block;
	font-size: 15px;
	background-color: #EEEEEE;
	color: #666666;
	border-radius: 8px;
	padding: 6px 12px 3px 13px;
	margin-top: 3px;
	margin-bottom: 9px;
	opacity: .8;
}

.update-bottom-container .update-reacts {
	font-family: semibold;
	font-size: 14px;
	margin-top: 5px;
	margin-right: 4px;
	color: #e67e22;
	/* color: #999999; */
	cursor: pointer;
	float: left;
	opacity: .5;
}

.update-bottom-container .update-reacts-count {
	margin-right: 8px;
}

.update-bottom-container .update-reacts:hover {
	opacity: .6;
}

.update-bottom-container .dark-reacts {
	opacity: 1;
}

.update-bottom-container .far {
	margin-right: 4px;
	font-size: 14px;
}

.update-expansion-container {
	width: 100%;
	height: 32px;
	margin-top: 35px;
	/* background-color: #F2F2F2; */
	/* margin-left: -15px; */
	/* padding: 0px 15px 14px 15px; */
}

.update-expansion-left-container {
	width: 43%;
	float: left;
	height: 32px;
	display: flex;
}

.update-react-button {
	flex-grow: 1;
	background-color: #EFEFEF;
	color: white;
	margin-right: 7px;
	border-radius: 8px;
	font-size: 14px;
	height: 24px;
	margin-top: 3px;
	text-align: right;
	padding: 4px 8px 0px 9px;
	overflow: auto;
}

.update-react-button .fas {
	margin-right: 3px;
	font-size: 14px;
	float: left;
	margin-top: 3px;
}

.update-react-button .fa-thumbs-up {
	font-size: 13px;
	vertical-align: top;
	margin-top: 3px;
	float: left;
}

.yellow-react {
	background-color: #fbc531
}

.red-react {
	background-color: #e84118;
}

.blue-react {
	background-color: #00a8ff;
}

.purple-react {
	background-color: #9c88ff;
}

.green-react {
	background-color: #4cd137;
}

[data-reacted="true"] {
	opacity: .5;
}

.update-expansion-center-container {
	width: calc(41% - 16px);
	height: 32px;
	float: left;
	padding-left: 8px;
	padding-right: 8px;
}

.update-share-button {
	width: 100%;
	text-align: center;
	padding-top: 5px;
	height: 27px;
	border-radius: 8px;
	background-color: #1FA0F2;
	color: white;
}

.update-share-button .fab {
	font-size: 14px;
	margin-right: 5px;
}

.update-expansion-right-container {
	width: calc(16% - 8px);
	float: right;
	height: 32px;
	padding-left: 8px;
}

.update-source-button {
	font-size: 15px;
	background-color: #EEEEEE;
	color: #999999;
	border-radius: 8px;
	padding: 6px 10px 4px 10px;
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
}

.update-source-button i {
	float: left;
	margin-right: 5px;
	font-size: 13px;
	margin-top: 3px;
}

.update-source-button:hover, .update-share-button:hover {
	opacity: .7;
}

[data-reacted="true"]:hover {
	opacity: .5!important;
}

.update-top-container {
	margin-bottom: 0px;
}

.manage-update-button {
	background-color: #CCCCCC;
	font-size: 13px;
	border-radius: 8px;
	width: auto;
	text-align: center;
	display: inline-block;
	color: white;
	font-family: semibold;
	padding: 2px 12px 0px 12px;
	margin: 0px 8px 0px 0px;
	cursor: pointer;
}

.update-edit-button {
	float: left;
}

.hidden-update {
	opacity: .5;
}

.quote-profile-image {
	width: 22px;
	height: 22px;
	border-radius: 11px;
	float: left;
	margin-top: 2px;
	margin-bottom: 2px;
	margin-left: -2px;
}

.importance-label {
	color: red;
	font-size: 15px;
	margin-top: 10px;
	margin-bottom: 6px;
}

.importance-label span {
	font-size: 12px;
	margin-right: 4px;
}

.manage-updates-container .importance-label {
	margin-bottom: -5px;
}

.notify-confirm-button {
	background-color: red !important;
}

.notify-confirm-button .fas {
	font-size: 11px;
	margin-right: 6px;
}

.feed-published-banner {
	width: calc(100% - 15px);
	padding-top: 12px;
	padding-bottom: 10px;
	background-color: #47CE4E;
	color: white;
	font-size: 16px;
	margin-top: 30px;
	font-family: semibold;
	padding-left: 15px;
	border-radius: 8px;
}

.feed-published-banner .far {
	font-size: 15px;
	margin-right: 7px;
}

.feed-archived-banner {
	width: calc(100% - 15px);
	padding-top: 12px;
	padding-bottom: 10px;
	background-color: #F34545;
	color: white;
	font-size: 16px;
	margin-top: 30px;
	font-family: semibold;
	padding-left: 15px;
	border-radius: 8px;
}

.feed-archived-banner .fas {
	font-size: 15px;
	margin-right: 7px;
}

.unpublished-updates-banner {
	width: calc(100% - 15px);
	padding-top: 12px;
	padding-bottom: 10px;
	background-color: #999999;
	color: white;
	font-size: 16px;
	margin-top: 30px;
	font-family: semibold;
	padding-left: 15px;
	border-radius: 12px;
}

.unpublished-updates-banner .fas {
	font-size: 15px;
	margin-right: 7px;
}

.feed-updates-separator {
	width: 100%;
	height: 2px;
	background-color: #dddddd;
	margin-top: -10px;
	margin-bottom: 42px;
}

.feed-update-container {
	height: 56px;
	width: 400px;
	border-radius: 8px;
	margin-top: 10px;
	margin-bottom: 5px;
	padding-top: 14px;
	transition: ease all .15s;
}

.feed-update-container:hover {
	transform: scale(0.99);
}

.feed-update-container:active {
	transform: scale(0.98);
}

.feed-update-image {
	width: 70px;
	height: 70px;
	float:left;
	border-radius: 8px 0px 0px 8px;
	margin-top: -14px;
	object-fit: cover;
	margin-right: 2px;
}

.feed-update-details {
	color: white;
	font-size: 13px;
	margin-top: 7px;
	opacity: .7;
}

.feed-update-details i {
	font-size: 11px;
	margin-left: 9px;
	margin-right: 3px;
}

.feed-update-button {
	float: right;
	margin-top: -40px;
	margin-right: 15px;
	color: white;
	border-radius: 20px;
	opacity: .7;
}

.feed-update-container .feed-slug {
	margin-left: 10px;
}

.update-preview-container .feed-update-container {
	width: 100%;
}

.update-preview-container .feed-update-container .feed-slug {
	margin-top: -2px;
	padding-left: 9px;
	padding-bottom: 3px;
	padding-top: 4px;
	font-size: 15px;
	margin-left: 8px;
	display: inline-block;
}

.update-preview-container .feed-update-container .feed-update-details {
	margin-top: 8px !important;
}

.update-preview-container .feed-update-container .feed-update-button {
	margin-top: -38px;
}

.custom-tooltip {
	font-family: semibold;
	font-size: 15px;
	width: 300px;
	border-radius: 8px;
	line-height: 1.3em;
	padding-top: 10px;
}

.toggle-background-button {
	background-color: #DDDDDD;
	display: inline-block;
	cursor: pointer;
	color: #888888;
	font-family: semibold;
	padding: 10px 12px 7px 12px;
	border-radius: 8px;
	transition: ease all .1s;
}

.fetch-all-button {
	margin-left: 20px;
}

.toggle-background-button:hover {
	transform: scale(0.98);
}

.toggle-background-button:active {
	transform: scale(0.95);
}

.toggle-background-button .fas {
	margin-right: 7px;
	font-size: 15px;
}

.toggle-background-button .queue-count {
	background-color: red;
	color: white;
	font-family: bold;
	font-size: 14px;
	padding-right: 5px;
	padding-left: 5px;
	padding-top: 3px;
	vertical-align: top;
	margin-left: 6px;
}

.update-text-container {
	clear: both;
	padding-top: 9px;
}

.queued-update-updates-container .update-text-container {
	padding-top: 0px;
}

.queue-right-container .update-text-container {
	padding-top: 0px !important;
}

.draft-right-container .update-text-container {
	padding-top: 0px !important;
}

.reviewing-update-author {
	font-size: 14px !important;
	color: #999999 !important;
	margin-left: 6px;
}

.update-bottom-crosspost-container {
	width: calc(100% - 22px);
	display: inline-block;
	/* background-color: #BBBBBB; */
	clear: both;
	color: white;
	border-radius: 8px;
	padding: 5px 10px 3px 12px;
	font-size: 15px;
	margin-bottom: 10px;
	margin-top: 7px;
	transition: ease all .1s;
}

.update-bottom-crosspost-container:hover {
	transform: scale(0.99);
}

.update-bottom-crosspost-container:active {
	transform: scale(0.97);
}

.update-bottom-crosspost-container i {
	float: right;
	padding-top: 4px;
	font-size: 14px;
}

.crosspost-id {
	font-family: courier;
	font-weight: bold;
	font-size: 14px;
}

.update-notes-container {
	background-color: #ffd32a;
	color: #555555;
	font-size: 15px;
	padding: 5px 9px 2px 9px;
	margin-top: 5px;
	margin-bottom: 5px;
	border-radius: 5px;
	display: inline-block;
}

.rejected-updates-header, .pending-updates-header {
	font-family: semibold;
	background-color: #DDDDDD;
	padding: 7px 0px 7px 0px;
	border-radius: 8px;
	width: 170px;
	text-align: center;
	color: #888888;
	margin-bottom: 30px;
}

.rejected-updates-header {
	margin-top: 80px;
}

.rejected-updates-container .fas, .pending-updates-container .fas {
	margin-top: 3px;
	margin-right: 7px;
}

.rejected-updates-container {
	margin-top: 80px;
}

.update-article-container {
	background-color: #f3f3f3;
	border-radius: 8px;
	padding: 7px 20px 15px 18px;
	margin-bottom: 10px;
	margin-top: 15px;
	transition: ease all 0.2s;
}

.update-article-container:hover {
	transform: scale(0.99);
}

.update-article-container .article-update-title {
	font-size: 16px;
	margin-top: 6px;
}

.update-article-container .article-update-site {
	font-size: 14px;
	margin-top: 2px;
}

.update-article-container-preview {
	background-color: #f3f3f3;
	border-radius: 8px;
	padding: 10px 16px 16px 14px;
	margin-bottom: 10px;
}

.article-update-image {
	width: 50px;
	height: 50px;
	float: left;
	border-radius: 8px;
	margin-right: 10px;
}

.article-update-favicon {
	width: 16px;
	height: 16px;
	float: left;
	border-radius: 8px;
	margin-right: 8px;
	margin-top: 3px;
}

.article-update-title {
	font-family: bold;
	color: #333333;
	font-size: 14px;
	line-height: 1.3em;
	margin-top: 2px;
}

.article-update-site {
	font-family: semibold;
	color: #9D9D9D;
	font-size: 12px;
	padding-top: 2px;
	white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
	margin-bottom: -7px;
}

.update-article-container-preview .article-update-site {
	padding-top: 4px;
}

.update-article-container-preview .article-update-favicon {
	margin-top: 4px;
}

.article-caption-text {
	margin-left: 2px;
}
