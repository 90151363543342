.login-background {
	position: fixed;
	top: 0px;
	right: 0px;
	left: 0px;
	bottom: 0px;
	background-color: white;
}

.login-container {
	width: 370px;
	position: fixed;
	top: 50%;
	left: 50%;
	margin-top: -240px;
	margin-left: -185px;
	z-index: 1001;
	background-color: white;
	border-radius: 12px;
}

.login-logo {
	height: 32px;
	margin-right: 1px;
	margin-top: -3px;
	float: left;
}

.login-title {
	font-size: 24px;
	margin-top: 8px;
	font-family: semibold;
}

.beta-text, .version-label {
	font-size: 14px;
	color: #999999;
	font-family: semibold;
	margin-left: 4px;
}

.login-top-container {
	display: block;
	margin: 0px auto;
	width: 146px;
	margin-bottom: 50px;
}

.login-container input {
	width: 60%;
	display: block;
	font-family: semibold;
	font-size: 16px;
	color: #333333;
	line-height: 1.4em;
	border-radius: 8px;
	padding: 9px 0px 8px 12px;
	margin: 0px auto;
	margin-top: 20px;
	margin-bottom: 14px;
	border: 0px solid #DFDFDF;
	background-color: #F3F3F3;
	height: 20px;
}

.login-container input:focus {
	outline: 0;
}

.facebook-login-button {
	width: 63%;
	display: block;
	font-family: semibold;
	font-size: 16px;
	color: white;
	border-radius: 8px;
	padding: 11px 17px 27px 17px;
	margin: 0px auto;
	margin-top: 20px;
	border: 0px;
	cursor: pointer;
	margin-bottom: 14px;
	background-color: #4267B2;
	height: 20px;
	transition: ease all .1s;
}

.facebook-login-button:hover {
	transform: scale(0.98);
}

.facebook-login-button:active {
	transform: scale(0.95);
}

.google-login-button {
	width: 63%;
	display: block;
	font-family: semibold;
	font-size: 16px;
	color: white;
	border-radius: 8px;
	padding: 11px 17px 27px 17px;
	margin: 0px auto;
	margin-top: 20px;
	border: 0px;
	cursor: pointer;
	margin-bottom: 14px;
	background-color: #CA4130;
	height: 20px;
	transition: ease all .1s;
}

.google-login-button:hover {
	transform: scale(0.98);
}

.google-login-button:active {
	transform: scale(0.95);
}

.sms-login-button, .username-login-button {
	width: 63%;
	display: block;
	font-family: semibold;
	font-size: 16px;
	color: white;
	border-radius: 8px;
	padding: 11px 17px 27px 17px;
	margin: 0px auto;
	margin-top: 20px;
	border: 0px;
	cursor: pointer;
	margin-bottom: 14px;
	background-color: #333333;
	height: 20px;
	transition: ease all .1s;
}

.username-login-button {
	background-color: #FF7054;
}

.sms-login-button:hover {
	transform: scale(0.98);
}

.sms-login-button:active {
	transform: scale(0.95);
}

.login-submit-button {
	text-align: center;
	width: 80px;
	color: white;
	font-family: semibold;
	padding: 8px 17px 6px 14px;
	font-size: 15px;
	border-radius: 12px;
	background-color: #00a8ff;
	cursor: pointer;
	display: block;
	margin: 0px auto;
	margin-bottom: 30px;
	margin-top: 30px;
	border-radius: 50px;
}

.login-submit-button:hover {
	opacity: .9;
}

.login-container .fas {
	margin-right: 8px;
	font-size: 13px;
}
