.draft-container {
	margin-left: 320px;
	padding-right: 60px;
}

.draft-left-container {
	width: calc(100% - 400px);
	float: left;
	padding-top: 40px;
}

.draft-right-container {
	position: fixed;
	width: 330px;
	top: 0px;
	bottom: 0px;
	overflow: scroll;
	right: 0px;
	padding: 40px;
	background-color: white;
}

.draft-notes-container {
	width: calc(100% - 30px);
	padding: 12px 15px 12px 15px;
	background-color: #F5F5F5;
	border-radius: 8px;
	margin-top: 10px;
	position: relative;
	margin-bottom: 30px;
	font-family:semibold;
}

.draft-section-label {
	font-family: bold;
	font-size: 12px;
	color: #BBBBBB;
}

.draft-type-selector-container {
	border-radius: 8px;
	font-family: semibold;
	padding: 9px 0px 7px 0px;
	line-height: 1.4em;
	color: #333333;
	margin-bottom: -30px;
	margin-left: -5px;
}

.update-info-container {
	background-color: white;
	margin-top: 10px;
	font-family: semibold;
	height: 24px;
	padding: 16px 16px 15px 13px;
	line-height: 1.4em;
	border-radius: 8px;
	color: #333333;
	margin-bottom: 30px;
}

.update-info-container .feed-slug {
	margin-left: 0px;
}

.update-scout-label {
	color: #999999;
	font-size: 14px;
	margin-left: 8px;
	font-family: semibold;
}

.update-scout-label .scout-name {
	color: #333333;
}

.update-info-left {
	float: left;
}

.draft-actions-container {
	display: inline;
	width: 130px;
	margin-left: 16px;
}

.draft-action-button {
	float: right;
	background-color: #CCCCCC;
	font-size: 13px;
	border-radius: 8px;
	margin-top: -0px;
	text-align: center;
	color: white;
	font-family: semibold;
	padding: 1px 12px 0px 12px;
	margin-right: 10px;
	cursor: pointer;
}

.draft-action-button:hover {
	opacity: .8;
}

.update-info-right {
	width: 200px;
	float: right;
	margin-top: 0px;
}

.update-stopwatch-container {
	width: auto;
	padding: 8px 12px 4px 12px;
	border-radius: 8px;
	background-color: #F5F5F5;
	float: right;
	margin-top: -5px;
	text-align: center;
}

.post-type-selector {
	display: flex;
	justify-content: space-between;
}

.post-type {
	background-color: white;
	width: 95px;
	padding-top: 8px;
	padding-bottom: 8px;
	text-align: center;
	border-radius: 8px;
	cursor: pointer;
	color: #333333;
	margin-right: 7px;
	margin-left: 7px;
}

.post-type:hover {
	background-color: #FCFCFC;
}

.active-post-type {
	background-color: #666666 !important;
	color: white;
}

.post-type .far, .post-type .fab, .post-type .fas {
	font-size: 13px;
	display: block;
	margin: 0px auto;
	margin-top: 5px;
	margin-bottom: 4px;
}

.update-draft-label {
	margin-top: 60px;
}

.update-preview-label {
	margin-top: 22px;
}

.admin-input-container {
	margin-top: 10px;
	padding: 0px 0px 8px 0px;
	border-radius: 8px;
	margin-left: -5px;
}

.admin-input {
	width: calc(100% - 25px);
	display: block;
	font-family: semibold;
	font-size: 16px;
	color: #333333;
	line-height: 1.4em;
	border-radius: 5px;
	padding: 9px;
	margin: 0px auto;
	margin-top: 12px;
	margin-bottom: 10px;
	border: 0px;
}

.article-url-input {
	margin-bottom: 35px;
}

.admin-post-input {
	height: 110px;
}

.admin-text-input {
	height: 20px;
}

.admin-caption-input {
	height: 70px;
}

textarea:focus, input:focus {
	outline-width: 0;
}

.admin-post-submit {
	text-align: center;
	color: white;
	padding: 11px 19px 10px 16px;
	display: inline-block;
	font-family: bold;
	font-size: 12px;
	border-radius: 8px;
	background-color: #666666; /* #00a8ff */
	margin-bottom: 8px;
	margin-top: 40px;
	cursor: pointer;
	transition: ease all .15s;
	margin-bottom: 70px;
	clear: both;
	float: left;
}

.admin-post-submit:hover {
	opacity: .9;
}

.admin-post-submit .fas {
	font-size: 14px;
	margin-right: 5px;
}

.create-top-news-draft-button {
	text-align: center;
	color: white;
	padding: 11px 19px 10px 16px;
	display: inline-block;
	font-family: bold;
	font-size: 12px;
	border-radius: 8px;
	background-color: #BBBBBB; /* #00a8ff */
	margin-top: 40px;
	cursor: pointer;
	transition: ease all .15s;
	margin-bottom: 50px;
	margin-left: 20px;
	float: left;
}

.create-top-news-draft-button:hover {
	opacity: .8;
}

.create-top-news-draft-button .fas {
	font-size: 14px;
	margin-right: 5px;
}

.submit-confirm {
	background-color: #4cd137;
}

.update-preview-container {
	width: 339px;
	margin-top: 10px;
	margin-bottom: -15px;
	overflow: auto;
}

.update-preview {
	background-color: white;
	border-radius: 8px;
	border: 2px solid #DEDEDE;
	padding: 9px 12px 9px 12px;
	font-family: semibold;
	font-size: 14px;
	line-height: 1.3em;
}

.update-preview-footer {
	width: 105%;
	margin-left: -8px;
	margin-bottom: -10px;
}

.update-preview .update-preview-image {
	width: 100%;
	border-radius: 5px;
	margin-top: 3px;
	margin-bottom: 3px;
}

.update-preview .update-preview-video {
	width: 100%;
	height: 185px;
	margin-top: 3px;
	margin-bottom: 3px;
}

.update-preview .update-link-button {
	font-size: 13px !important;
	background-color: #EEEEEE;
	color: #999999;
	border-radius: 8px;
	padding: 7px 10px 5px 10px !important;
	margin-top: 8px;
	margin-bottom: 8px;
	cursor: pointer;
	display: inline-block;
	float: left;
	clear: left;
}

.update-preview .update-link-button .fa-chevron-right {
	font-size: 11px;
	margin-top: 2px;
	margin-left: 6px;
	float: right;
}

.update-preview .update-link-button:hover {
	opacity: .8;
}

.update-preview .update-quote-details .fa-comment {
	float: left;
	margin-right: 4px;
	color: #888888;
	font-size: 12px;
	margin-top: 2px;
}

.update-preview .update-question-text {
	display: inline-block;
	font-size: 14px;
	background-color: #EEEEEE;
	color: #444444;
	border-radius: 8px;
	padding: 4px 10px 4px 10px;
	margin-top: 3px;
	margin-bottom: 7px;
	opacity: .8;
}

.source-input-container {
	width: 70%;
	margin-top: 10px;
	font-family: semibold;
	line-height: 1.4em;
	border-radius: 8px;
	color: #333333;
}

.update-source-label {
	margin-top: 48px;
}

.update-crosspost-label {
	margin-top: 80px;
}

.update-time-label {
	margin-top: 86px;
}

.update-time-label span {
	cursor: pointer;
	text-decoration: underline;
}

.update-radios-container {
	clear: both;
	padding-top: 24px;
}

.update-radio-button {
	width: 16px;
	height: 16px;
	border-radius: 30px;
	border: 4px solid white;
	background-color: white;
	cursor: pointer;
	float: left;
	transition: ease all .2s;
}

.update-radio-button:hover {
	opacity: .8;
}

.image-radios-input {
	margin-top: -15px;
	margin-bottom: 25px;
}

.update-radio-label {
	float: left;
	font-family: bold;
	margin-right: 18px;
	margin-left: 8px;
	color: #BBBBBB;
	margin-top: 7px;
	font-size: 12px;
}

.update-checkbox-button {
	width: 16px;
	height: 16px;
	border-radius: 2px;
	border: 4px solid white;
	background-color: white;
	cursor: pointer;
	float: left;
	transition: ease all .2s;
}

.update-source-input {
	width: 100%;
}

.update-crosspost-input {
	width: 65% !important;
}

.update-gif-message {
	font-family: semibold;
	color: red;
	margin-left: 5px;
	padding-top: 5px;
}

.update-time-input {
	width: 97% !important;
	cursor: pointer;
}

.update-time-input:hover {
	background-color: #FAFAFA;
}

.sketch-picker {
	z-index: 999999;
	position: absolute;
	top: 215px;
	left: 321px;
}

.latest-updates-label {
	margin-top: 30px;
	margin-bottom: -10px;
}

.sidebar-updates-container {
	font-size: 14px;
}

.sidebar-updates-container .update-container {
	background-color: #f5f5f5;
	cursor: default;
}

.sidebar-updates-container .update-twitter-container {
	width: 303px !important;
}

.sidebar-updates-container .update {
	margin-top: 25px;
}

.sidebar-updates-container .update-bottom-crosspost-container {
	font-size: 13px;
}

.sidebar-updates-container .update-bottom-crosspost-container i {
	font-size: 11px;
	padding-top: 2px;
}

.sidebar-updates-container .update-link-button {
	background-color: white;
	color: #555555;
}

.sidebar-updates-container .update-quote-details {
	background-color: white;
	color: #555555;
}

.sidebar-updates-container .feed-published-banner {
	font-size: 14px;
	line-height:1.4em;
}

.sidebar-updates-container .feed-published-banner .far {
	font-size: 13px;
	line-height:1.4em;
}

.sidebar-updates-container .feed-archived-banner {
	width: calc(100% - 25px);
	padding-right: 10px;
	font-size: 14px;
	line-height:1.4em;
}

.sidebar-updates-container .feed-archived-banner .fas {
	font-size: 13px;
	line-height:1.4em;
}

.sidebar-updates-container .unpublished-updates-banner {
	font-size: 14px;
}

.sidebar-updates-container .unpublished-updates-banner .fas {
	font-size: 12px;
}

.sidebar-updates-container .update-quote-source {
	margin-top: 4px;
}

.sidebar-updates-container .update-question-text {
	font-size: 14px;
	padding: 6px 11px 5px 11px;
}

.sidebar-updates-container .importance-label {
	margin-top: 0px;
	font-size: 13px;
}

.sidebar-updates-container .importance-label .fas {
	font-size: 10px;
}

.sidebar-updates-container .feed-update-container {
	width: 100%;
}

.sidebar-updates-container .feed-update-container .feed-slug {
	margin-top: -2px;
	padding-left: 9px;
	padding-bottom: 3px;
	padding-top: 4px;
	font-size: 15px;
	margin-left: 8px;
	margin-bottom: 0px;
	display: inline-block;
}

.sidebar-updates-container .feed-update-container .feed-update-details {
	margin-top: -3px !important;
}

.draft-right-item .sidebar-updates-container .feed-update-container .feed-update-details {
	margin-top: 7px !important;
}

.sidebar-updates-container .feed-update-container .feed-update-button {
	margin-top: -38px;
}

.sidebar-updates-container .update-article-container {
	background-color: white;
	margin-top: 5px;
	margin-bottom: 3px;
	padding-top: 5px;
}

.sidebar-updates-container .update-article-container .article-update-title {
	font-size: 14px;
	margin-left: -3px;
}

.sidebar-updates-container .update-article-container .article-update-favicon {
	margin-left: -4px;
}

.sidebar-updates-container .update-article-container .article-update-site {
	font-size: 13px;
}

.source-link {
	font-family: semibold;
	text-overflow: ellipsis;
	background-color: #f3f3f3;
	color: #666666;
	white-space: nowrap;
  overflow: hidden;
	padding: 15px 15px 12px 15px;
	border-radius: 12px;
	transition: ease all .15s;
	margin-top: 12px;
}

.source-link:hover {
	transform: scale(0.98);
}

.source-link:active {
	transform: scale(0.95);
}

.source-link i {
	padding-right: 7px;
	font-size: 15px;
}

.draft-mobile-source .source-link {
	background-color: white;
}

.top-news-notice {
	margin-top: 50px;
	color: #999999;
	font-size: 14px;
	cursor: pointer;
	font-family: bold;
}

.draft-mobile-source {
	margin-bottom: 30px;
	display: none;
}
