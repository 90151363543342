@media only screen and (max-width: 1530px) {
  .manage-updates-top-container {
  	margin-left: 335px;
  }
  .manage-updates-container {
  	margin-left: 335px;
  }
}

/* iPad Pro 11-inch changes */
@media only screen and (max-width: 1440px) {
  .queue-right-container {
		display: none;
	}
	.queue-left-container {
		width: 100%;
	}
  .manage-feeds-container {
    width: calc(100% - 190px);
  }
  .feeds-container {
    margin-left: 315px;
  }
  .feed-details-label {
    float: left;
    clear: both;
    margin-top: 12px;
    margin-bottom: 7px;
  }
  .managed-feed {
    padding-top: 13px;
  }
  .manage-feed-button {
    padding-left: 8px;
    padding-right: 8px;
  }
  .manage-feed-description {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
  }
  .feeds-right-container {
    width: 250px;
  }
  .feed-slug {
    font-size: 15px;
    padding-top: 6px;
    padding-bottom: 3px;
  }
  .feeds-container .feed-slug {
    float: left;
  }
  .user-leaderboard-item {
  	width: calc(100% - 30px);
  	padding: 10px 12px 12px 11px !important;
  	margin-top: 5px;
  	margin-bottom: 14px;
  }
  .leaderboard-score {
    padding-top: 7px;
    font-size: 14px;
  }
  .update-stopwatch-container {
    display: none;
  }
  .draft-right-container {
    width: 270px;
    padding-left: 25px;
    padding-right: 25px;
  }
  .draft-right-container .update-text-container {
    font-size: 14px;
  }
  .draft-container {
    margin-left: 300px;
  }
  .draft-left-container {
    width: calc(100% - 290px);
  }
  .faq-checkbox-container {
    float: left;
    clear: both;
    margin-top: 13px;
  }
  .faq-crosspost-container {
    float: left;
    margin-top: 13px;
  }
}

@media only screen and (max-width: 1180px) {
	.queue-container {
		width: calc(100% - 415px);
	}
}

@media only screen and (max-width: 1000px) {
  .mobile-menu-button {
    position: fixed;
    display: block;
    top: 20px;
    left: 28px;
    z-index: 999999;
  }
  .queue-top-container {
    margin-top: 23px;
  }
  .manage-feeds-top-button {
    padding-right: 10px;
    padding-left: 12px;
    font-size: 12px;
    margin-right: 9px;
  }
  .sidebar-top-container {
    margin-top: 40px;
  }
  .sidebar-title {
    display: none;
  }
  .sidebar-container {
    z-index: 99999;
  }
  .queue-container {
    margin-left: 0px;
    width: 90%;
    display: block;
    margin: 0px auto;
  }
  .queued-update-updates-container {
    display: none;
  }
  .queued-update-tweet-container {
    width: 100%;
    margin-left: -12px;
    margin-top: -5px;
  }
  .update-scout-label {
    display: none;
  }
  .queued-update .feed-slug {
    font-size: 14px;
  }
  .queue-claim-button {
    font-size: 12px;
    width: 55px;
  }
  .queue-reject-button {
    width: 55px;
    text-align: center;
    font-size: 12px;
    padding-left: 0px;
    padding-right: 0px;
    margin-right: 8px;
  }
  .queue-swap-button {
    width: 24px;
    font-size: 12px;
  }
  .draft-container {
    margin-left: 15px;
    margin-right: 15px;
  }
  .draft-right-container {
    display: none;
  }
  .draft-left-container {
    width: 100%;
    margin-top: 40px;
    float: none;
  }
  .update-info-container {
    width: 100%;
  }
  .update-info-right {
    display: none;
  }
  .draft-action-button {
    font-size: 12px;
  }
  .draft-container .feed-slug {
    font-size: 13px;
  }
  .draft-mobile-source {
    display: block;
  }
  .manage-updates-top-container {
  	margin-left: 20px;
  }
  .manage-updates-container {
  	margin-left: 20px;
    width: 90%;
  }
  .feed-buttons-container {
    width: calc(100% - 15px);
  }
  .queue-label {
    display: none;
  }
  .mobile-leaderboard-container {
    display: none;
  }
  .feeds-container {
    width: calc(100% - 40px);
  	margin-left: 20px;
  }
  .manage-feeds-container {
    width: 100%;
  }
}
