.batch-container {
	width: 820px;
	margin-left: 340px;
	padding-bottom: 80px;
	padding-top: 50px;
}

.countdown-container {
	padding: 9px 15px 7px 15px;
	border-radius: 8px;
	text-align: center;
	font-size: 17px;
	color: white;
	font-family: semibold;
	margin-right: 18px;
	transition: ease all .15s;
	background-color: #E04D4A;
}

.batch-updates-container {
	width: 100%;
	clear: both;
	padding-bottom: 80px;
	margin-top: 10px;
}

.batch-top-container {
	float: left;
	display: flex;
}

.batch-separator {
	width: 860px;
	height: 2px;
	background-color: #DDDDDD;
	margin-top: 70px;
}

.batch-updates-container .feed-slug {
	margin-left: 0px;
	display: inline-block;
	margin-top: 32px;
	padding-bottom: 3px;
}

.batch-updates-container .update {
	margin-top: 22px;
}

.clear-current-button {
	background-color: #AAAAAA;
}
