* {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

@font-face {
	font-family: regular;
	src: url('../fonts/AvenirNextLTPro-Regular.otf');
}

@font-face {
	font-family: semibold;
	src: url('../fonts/AvenirNextLTPro-Demi.otf');
}

@font-face {
	font-family: medium;
	src: url('../fonts/AvenirLTStd-Medium.otf');
}

@font-face {
	font-family: bold;
	src: url('../fonts/AvenirNextLTPro-Bold.otf');
}

body {
	background-color: #F2F2F2;
}

a {
	text-decoration: none;
	color: inherit;
}

.app-loading-container {
	position: absolute;
	width: 150px;
	margin-left: auto;
	margin-right: auto;
	left: 0;
	right: 0;
	padding-top: 260px;
}

.manage-feeds-loading-container {
	display: block;
	width: 150px;
	padding-top: 180px;
	margin-left: 320px;
}

.updates-loading-container {
	display: block;
	width: 150px;
	padding-top: 280px;
	margin-left: 700px;
}

.mobile-menu-button {
	display: none;
	color: #999999;
	padding: 10px 12px 9px 12px;
	border-radius: 20px;
	background-color: #DDDDDD;
}
