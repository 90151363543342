.feeds-container {
	width: calc(100% - 500px);
	margin-left: 360px;
	padding-bottom: 50px;
	padding-top: 55px;
}

.manage-feeds-container {
	width: calc(100% - 312px);
	border-radius: 8px;
	margin-bottom: 40px;
	margin-top: 30px;
}

.feeds-right-container {
	position: fixed;
	width: 280px;
	top: 0px;
	bottom: 0px;
	right: 0px;
	overflow: scroll;
	padding: 20px 40px 40px 40px;
	background-color: white;
}

.feeds-right-container .feed-slug {
	margin-left: 0px;
	opacity: 1;
}

.feeds-right-container .user-leaderboard-item {
	padding: 16px 15px 16px 13px;
	transition: ease all .1s;
}

.feeds-right-container .user-leaderboard-item:hover {
	transform: scale(0.98);
}

.feeds-right-container .user-leaderboard-item:active {
	transform: scale(0.95);
}

.manage-feeds-top-container {
	float: left;
	margin-bottom: 75px;
	display: flex;
}

.manage-feed-description {
	margin: 35px 15px 0px 15px;
	font-family: medium;
	color: #999999;
	font-size: 15px;
	width: 785px;
	line-height: 1.3em;
	clear: both;
}

.managed-feed-buttons-container {
	display: flex;
	flex-wrap: wrap;
	margin: 2px 0px 8px 15px;
}

.manage-feed-button {
	background-color: #CCCCCC;
	font-size: 13px;
	border-radius: 8px;
	margin-top: 8px;
	text-align: center;
	color: white;
	font-family: semibold;
	padding: 4px 12px 3px 12px;
	margin-right: 10px;
}

.manage-feed-button:hover {
	opacity: .8;
}

.feed-streaming-button {
	background-color: #87D1F0;
}

.feed-live-button {
	background-color: #ff8f8a;
}

.new-update-button {
	text-align: center;
	padding: 1px 4px 0px 4px;
	background-color: #2ecc71;
	margin-top: 11px;
	height: 16px;
}

.new-update-button-big {
	background-color: #47CE4E;
}

.managed-feed {
	width: calc(100% - 25px);
	background-color: white;
	border-radius: 8px;
	height: auto;
	padding: 17px 20px 5px 4px;
	cursor: pointer;
	overflow: hidden;
	margin-bottom: 30px;
}

.manage-updates-container .managed-feed {
	margin-bottom: 55px;
}

.feed-details-label {
	color: #999999;
	font-size: 14px;
	margin-left: 15px;
	font-family: semibold;
	margin-top: 5px;
	margin-bottom: 3px;
	float: right;
}

.feed-details-label .bold-text {
	color: #333333;
}

.feed-input-container {
	margin-top: 10px;
	font-family: semibold;
	line-height: 1.4em;
	color: #333333;
	margin-bottom: 26px;
	overflow: auto;
}

.archived-feeds-container .feed-slug {
	opacity: .7;
}

.unpublished-feeds-container .feed-slug {
	opacity: .7;
}

.feed-input-container .feed-slug {
	margin-left: 14px;
	display: inline-block;
	padding: 3px 7px 2px 7px;
	margin-top: 6px;
}

.feed-input-container .color-slug {
	margin-left: 0px;
	padding: 7px 10px 6px 10px;
	border-radius: 8px;
	cursor: pointer;
	margin-top: 0px;
	margin-bottom: 7px;
}

.feed-input-container .color-slug:hover {
	transform: scale(0.98);
}

.feed-input-container .color-slug:active {
	transform: scale(0.95);
}

.feed-input {
	width: 100%;
	display: block;
	font-family: semibold;
	font-size: 16px;
	color: #333333;
	line-height: 1.4em;
	border-radius: 8px;
	padding: 10px;
	margin: 0px auto;
	margin-top: 12px;
	margin-bottom: 10px;
	border: 0px;
}

.feed-image-container {
	width: 50%;
}

.feed-text-input {
	height: 20px;
	float: left;
	margin-top: 0px;
}

.feed-image-input {
	margin-bottom: 15px;
	width: 70%;
}

.emoji-input {
	margin-bottom: 5px;
	cursor: pointer;
	font-size: 22px;
	padding-top: 16px;
	padding-left: 12px;
	width: 26px;
	transition: ease all .15s;
}

.emoji-input:hover {
	transform: scale(0.98);
}

.emoji-input:active {
	transform: scale(0.95);
}

.feed-description-input {
	width: 96%;
	float: left;
	margin-top: 0px;
	height: 70px;
}

.feed-name-input {
	width: 130px;
}

.feed-color-input {
	width: 85px;
}

.feed-category-input {
	width: 160px;
}

.feed-keywords-input {
	width: 220px;
}

textarea:focus, input:focus {
	outline-width: 0;
}

.feed-radios-container {
	clear: both;
	padding-top: 1px;
	margin-bottom: 54px;
}

.managed-feed-left-container {
	float: left;
}

.manage-feeds-top-button {
	padding: 10px 15px 7px 15px;
	border-radius: 8px;
	text-align: center;
	font-size: 15px;
	color: white;
	font-family: semibold;
	cursor: pointer;
	margin-right: 18px;
	transition: ease all .15s;
}

.manage-feeds-top-button:hover {
	transform: scale(0.98);
}

.manage-feeds-top-button:active {
	transform: scale(0.95);
}

.manage-feeds-top-button .fas,.manage-feeds-top-button .far {
	font-size: 12px;
	margin-right: 6px;
}

.show-section-button {
	padding: 10px 15px 7px 15px;
	border-radius: 8px;
	text-align: center;
	font-size: 15px;
	color: white;
	font-family: semibold;
	cursor: pointer;
	margin-right: 18px;
	transition: ease all .15s;
	background-color: #BBBBBB;
	width: 180px;
	margin-bottom: 20px;
	clear: both;
}

.show-section-button:hover {
	transform: scale(0.98);
}

.show-section-button:active {
	transform: scale(0.95);
}

.users-section-button {
	margin-top: 40px;
	margin-bottom: 10px;
}

.show-section-button .fas {
	font-size: 12px;
	margin-left: 6px;
}

.add-feed-button {
	background-color: #47CE4E;
}

.coverage-toggle-button {
	background-color: #999999;
}

.publish-batch-button {
	background-color: #AAAAAA;
}

.confirm-warning {
	background-color: #E04D4A;
}

.manage-search {
	width: 240px;
	border-radius: 8px;
	font-family: semibold;
	font-size: 15px;
	color: #666666;
	line-height: 1.4em;
	padding: 8px 12px 7px 12px;
	border: 0px;
}

.bottom-feed-button {
	text-align: center;
	color: white;
	padding: 11px 16px 10px 16px;
	display: inline-block;
	font-family: bold;
	font-size: 12px;
	border-radius: 8px;
	cursor: pointer;
	transition: ease all .15s;
	margin: 10px 15px 60px 0px;
	clear: both;
	float: left;
}

.bottom-feed-button:hover {
	opacity: .9;
}

.bottom-feed-button .fas, .bottom-feed-button .far {
	font-size: 14px;
	margin-right: 5px;
}

.feed-right-buttons-container {
	float: right;
}

.save-button {
	background-color: #4cd137;
}

.create-feed-button {
	background-color: #BBBBBB;
}

.feature-button {
	background-color: #f9ca24;
}

.delete-button {
	background-color: #e84118;
}

.archive-button {
	background-color: #BBBBBB;
}

.manage-feeds-label {
	clear: both;
	font-family: semibold;
	margin-bottom: -10px;
	font-size: 18px;
	color: #333333;
}

.notify-submit-button {
	margin-top: 13px !important;
}

.notify-warning-button {
	background-color: #ff4d4d !important;
}

.feed-gif-message {
	font-family: semibold;
	color: red;
	margin-left: 3px;
	padding-top: 0px;
	margin-bottom: 12px;
	clear: both;
}

.notify-warning-button .fas {
	font-size: 11px;
	margin-right: 5px;
}

.react-tagsinput {
  background-color: #fff !important;
  border-radius: 10px !important;
  width: 550px !important;
	border: 0px !important;
  overflow: hidden !important;
  padding-left: 10px !important;
  padding-top: 10px !important;
}

.react-tagsinput-tag {
  background-color: #F2F2F2 !important;
  border-radius: 2px !important;
  border: 2px solid #eeeeee !important;
  color: #999999 !important;
  display: inline-block !important;
  font-family: semibold !important;
  font-size: 15px !important;
  border-radius: 8px !important;
  font-weight: 400 !important;
  margin-bottom: 10px !important;
  margin-right: 10px !important;
  padding: 5px 9px 2px 9px !important;
}

.react-tagsinput-remove {
  cursor: pointer !important;
}

.react-tagsinput-tag a::before {
  content: " ×" !important;
}

.react-tagsinput-input {
  background: transparent !important;
  border: 0 !important;
  color: #777 !important;
  font-family: semibold !important;
  font-size: 16px !important;
	color: #333333 !important;
  margin-bottom: 6px !important;
  margin-top: 1px !important;
  outline: none !important;
  padding: 2px 5px 5px 0px !important;
  width: 140px !important;
}

.coverage-buttons-container {
	display: flex;
	margin-bottom: 40px;
}

.coverage-button {
	width: 90px;
	background-color: white;
	border-radius: 8px;
	text-align: center;
	padding-top: 17px;
	padding-bottom: 12px;
	color: #757575;
	font-size: 15px;
	margin-right: 15px;
	cursor: pointer;
}

.coverage-button i {
	width: 100%;
}

.coverage-button:hover {
	transform: scale(0.98);
}

.coverage-button:active {
	transform: scale(0.95);
}

.selected-coverage {
	background-color: #888888;
	color: white;
}

.tooltip-container {
	padding-bottom: 10px !important;
}

.emoji-section-label {
	clear: both;
}

.feed-tile-previews {
	width: 385px;
	display: block;
}

.feed-tile-preview {
	margin-top: 12px;
	width: 180px;
	border-radius: 12px;
	margin-bottom: 30px;
	padding-bottom: 5px;
}

.left-preview {
	float: left;
}

.right-preview {
	float: right;
}

.tile-preview-image-container img {
	width: 180px;
	height: 55px;
	object-fit: cover;
	border-radius: 12px 12px 0px 0px;
	margin-bottom: 8px;
}

.tile-preview-description {
	color: white;
	opacity: .8;
	font-family: semibold;
	font-size: 13px;
	margin: 10px 12px -6px 12px;
	max-height: 49px;
	overflow: hidden;
}

.feed-tile-metadata {
	font-size: 13px;
	font-family: semibold;
	margin-top: 10px;
	color: white;
	font-weight: 500;
	opacity: .8;
	margin-left: 12px;
	user-select: none;
}

.feed-tile-metadata .fas {
	margin-right: 4px;
	font-size: 10px;
}

.feed-tile-metadata .fa-sync-alt {
	margin-left: 7px;
}

.manage-feed-emoji {
	vertical-align: top;
	display: inline-block;
	margin-top: -6px;
	margin-right: 5px;
}
