.queue-container {
	width: calc(100% - 500px);
	margin-left: 340px;
	padding-bottom: 50px;
	padding-top: 55px;
}

.queue-top-container {
	float: left;
	margin-bottom: 40px;
	display: flex;
}

.queue-right-container {
	position: fixed;
	width: 331px;
	top: 0px;
	bottom: 0px;
	right: 0px;
	overflow: scroll;
	padding: 20px 40px 40px 40px;
	background-color: white;
}

.queue-left-container {
	width: calc(100% - 320px);
	border-radius: 8px;
	padding: 0px 0px 20px 2px;
	margin-bottom: 40px;
	clear: both;
}

.queued-update {
	width: calc(100% - 26px);
	background-color: white;
	border-radius: 8px;
	margin-top: 35px;
	height: auto;
	padding: 20px 20px 9px 6px;
	overflow: hidden;
}

.queued-update-left-container {
	float: left;
}

.queued-update-right-container {
	float: right;
}

.queued-update-bottom-container {
	width: 100%;
	margin-top: 20px;
	padding: 14px 20px 0px 20px;
	overflow: hidden;
  position: relative;
}

.queued-update-tweet-container {
	width: 49%;
	float: left;
}

.queued-update-updates-container {
	width: calc(49% - 75px);
	height: 100%;
	position: absolute;
  right: 40px;
  top: 23px;
	overflow: scroll;
	font-size: 14px;
	background-color: #EEEEEE;
	border-radius: 8px;
	padding: 0px 15px 0px 15px;
}

.queued-update-updates-container .update {
	margin-top: 20px;
}

.queued-update-updates-container .update-twitter-container {
	width: 355px !important;
}

.queued-update-updates-container .update-question-text {
	font-size: 14px;
	padding: 6px 11px 5px 11px;
}

.queued-update-updates-container .feed-published-banner {
	font-size: 14px;
	line-height:1.4em;
}

.queued-update-updates-container .unpublished-updates-banner {
	font-size: 14px;
}

.queued-update-updates-container .unpublished-updates-banner .fas {
	font-size: 12px;
}

.queued-update-updates-container .feed-published-banner .far {
	font-size: 13px;
	line-height:1.4em;
}

.queued-update-updates-container .update-quote-source {
	margin-top: 4px;
}

.queued-update-updates-container .importance-label {
	margin-top: 0px;
	font-size: 13px;
}

.queued-update-updates-container .importance-label .fas {
	font-size: 10px;
}

.queued-update-updates-container .feed-update-container {
	width: 100%;
}

.queued-update-updates-container .feed-update-container .feed-slug {
	margin-top: -2px;
	padding-left: 9px;
	padding-bottom: 3px;
	padding-top: 4px;
	font-size: 15px;
	margin-left: 8px;
	display: inline-block;
}

.queued-update-updates-container .feed-update-container .feed-update-details {
	margin-top: 7px;
}

.queued-update-updates-container .feed-update-container .feed-update-button {
	margin-top: -38px;
}

.queue-updates .feed-slug {
	display: inline-block;
	margin-bottom: 10px;
	margin-top: 24px;
	margin-left: 2px;
	padding-bottom: 0px;
	padding-left: 0px;
	font-size: 15px;
	/* color: #333333 !important; */
}

.queue-updates .feed-slug .fas {
	font-size: 13px;
}

.queue-updates .update {
	margin-top: 0px !important;
}

.queue-reject-button {
	float: right;
	background-color: #f3f3f3;
	border-radius: 8px;
	margin-top: -6px;
	margin-right: 15px;
	text-align: center;
	color: #999999;
	font-family: semibold;
	font-size: 15px;
	padding: 9px 24px 6px 24px;
	cursor: pointer;
	transition: ease all .15s;
}

.queue-claim-button {
	float: right;
	width: 80px;
	background-color: #47CE4D;
	border-radius: 8px;
	margin-top: -5px;
	text-align: center;
	color: white;
	font-family: semibold;
	padding: 7px 0px 5px 0px;
	cursor: pointer;
	transition: ease all .15s;
}

.queue-swap-button {
	float: right;
	width: 55px;
	margin-top: -5px;
	text-align: center;
	color: #999999;
	margin-right: 15px;
	font-family: semibold;
	padding: 7px 0px 5px 0px;
	cursor: pointer;
	transition: ease all .15s;
}

.queued-update-right-container .confirm-warning {
	color: white;
	padding: 9px 20px 6px 20px;
}

.queue-claim-button:hover, .queue-reject-button:hover, .queue-swap-button:hover {
	transform: scale(0.98);
}

.queue-claim-button:active, .queue-reject-button:active, .queue-swap-button:active {
	transform: scale(0.95);
}

.queue-section-label {
	font-family: bold;
	font-size: 12px;
	color: #BBBBBB;
	margin-bottom: 14px;
}

.queue-label {
	font-family: bold;
	font-size: 12px;
	color: #BBBBBB;
	margin-bottom: 14px;
	margin-top: 75px;
}

.second-label {
	margin-top: 30px;
}

.feed-buttons-container {
	background-color: white;
	padding-top: 15px;
	padding-bottom: 15px;
	padding-right: 15px;
	width: calc(100% - 338px);
	overflow: auto;
  white-space: nowrap;
	border-radius: 12px;
	padding-left: 5px;
}

.batch-queue-container {
	margin-top: 60px;
}

.feed-buttons-container .feed-slug {
	padding: 7px 9px 5px 9px;
}

::-webkit-scrollbar {
    width: 0px;
		height: 4px;
    background: transparent;
		border-radius: 2px;
}

::-webkit-scrollbar-thumb {
    background: #DDDDDD;
		border-radius: 2px;
}

.feed-button {
	cursor: pointer;
	display:inline-block;
	transition: ease all .15s;
	font-size: 15px;
}

.feed-button:hover {
	transform: scale(0.98);
}

.selected-feed-button {
	transform: scale(0.95) !important;
	opacity: .6;
}

.queue-count {
	background-color: white;
	margin-left: 5px;
	border-radius: 15px;
	padding: 4px 4px 2px 4px;
	font-size: 12px;
}

.queued-question-container {
	font-family: semibold;
	background-color: white;
	border-radius: 8px;
	color: #444444;
	font-size: 20px;
	height: 220px;
	line-height: 1.4em;
	margin: 9px 0px 10px -5px;
	padding: 12px 16px 0px 16px;
}

.user-id {
	font-family: courier;
	font-weight: bold;
	color: #333333;
}

.questions-section-title {
	font-size: 19px;
	font-family: semibold;
	color: #666666;
	margin-top: 40px;
	margin-bottom: -12px;
}
